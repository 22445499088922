import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import globalVariable from '../../global';
import CurrencyFormat from '../other/CurrencyFormat';
import { currencyFormat, roundNum, roundNumberCeil, removeSpecialChar } from "../../helperFunctions";
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const CustomExportCatalogItem = ({ item, key, removeItemFromCatalog, inputValuePercentage }) => {

    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const [valueInput, setValueInput] = useState(roundNumberCeil(item.anPrice - (item.anPrice * inputValuePercentage / 100)));
    const [defaultValue, setDefaultValue] = useState(currencyFormat(parseInt(valueInput)));
    const { t } = useTranslation('routes');

    useEffect(() => {
        setValueInput((item.anPrice + (item.anPrice * inputValuePercentage / 100)).toFixed(0));
        setDefaultValue(currencyFormat(parseInt((item.anPrice + (item.anPrice * inputValuePercentage / 100)).toFixed(0))))
    }, [inputValuePercentage, item.anPrice]);

    const setValueInputFunction = (val) => {

        setValueInput(val);
        val = val.replace(",", ".");

        setDefaultValue(0)

        let ls = new SecureLS({ encodingType: 'aes' });
        var tmpCatalog = ls.get('catalogItems');
        tmpCatalog.findIndex(function (element) {
            if (element._id === item._id) {
                element.anPricePdf = parseFloat(roundNumberCeil(val));
            }
        });
        ls.set('catalogItems', tmpCatalog);
    }

    return (
        <tr>
            <td className="column-no-width">{item.acProduct}</td>
            <td className="custom-export-catalog-image column-no-width">
                <img className="img-fluid" src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}.jpg`} alt={item.acName} />
            </td>
            <td className="custom-offer-product-name">
                <Link to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`} title={item.acDept.replace(" 1****", "") + " " + item.acName}>
                    {item.acDept.replace(" 1****", "")} {item.acName}
                </Link>
            </td>
            <td className="old-price-custom-export-catalog custom-offer-price">
                {item.anOldPrice > 0 && (
                    <>
                        <CurrencyFormat num={item.anOldPrice} /> {activeCurrency.acShortName}
                    </>
                )}
            </td>
            <td className="custom-offer-price">
                <CurrencyFormat num={item.anPrice} /> {activeCurrency.acShortName}
            </td>
            <td className="custom-offer-input">
                <input
                    type="text"
                    className="form-control text-right"
                    value={defaultValue != 0 ? defaultValue : valueInput}
                    onChange={(e) => { setValueInputFunction(e.target.value) }}
                />
            </td>
            <td>{activeCurrency.acShortName}</td>
            <td
                className="custom-export-icon-remove"
                onClick={(e) => removeItemFromCatalog(item)}
            >
                <i className="fas fa-times"></i>
            </td>
        </tr>
    )
};

export default CustomExportCatalogItem;