import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDetailPdf from './templates/ProductDetailPdf';
import { PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetail } from "../../redux/actions/productActions";
import { getProductSpecification } from "../../redux/actions/productActions";

const ProductPdf = () => {
    const { productId } = useParams();
    const dispatch = useDispatch();
    const productsDetail = useSelector((state) => state.productDetail.items);
    const currency = useSelector((state) => state.activeCurrency.item.acShortName);
    const productsDetailSpec = useSelector((state) => state.getProductSpecification);
    const anActiveVat = JSON.parse(localStorage.getItem('activeVat'));
    const getB2bSettings = useSelector((state) => state.getB2bSettings);
    // console.log(getB2bSettings, 'getB2bSettings');    
    const acLogOutUserCurrency = getB2bSettings.items ? getB2bSettings.items.acLogOutUserCurrency : '';

    useEffect(() => {

        dispatch(getProductDetail(productId));
        dispatch(getProductSpecification(productId));
    }, []);

    if (productsDetailSpec) {
        console.log('productsDetailSpec', productsDetailSpec);
        if (!productsDetailSpec.list) {
            return null;
        }
    } else {
        console.log('no product detail spec', productsDetailSpec);
        return null
    }

    return (
        <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            {productsDetail.length > 0 && <ProductDetailPdf item={productsDetail[0]} spec={productsDetailSpec.list} productId={productId} badges2={productsDetailSpec.filterIcons} activeVat={anActiveVat} currency={currency}
                acLogOutUserCurrency={acLogOutUserCurrency} />}
        </PDFViewer>
    );
}

export default ProductPdf;
