import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import messages from '../../messages';
import { toast } from 'react-toastify';

import { getProductSpecification } from '../../redux/actions/productActions';

import CurrencyFormat from '../other/CurrencyFormat';
import ModalLoyalty from '../other/ModalLoyalty';
import MessageInfoBox from '../../components/other/MessageInfoBox';
import { useTranslation } from 'react-i18next';


const LoyaltyItem = ({ item, loyaltySettings, getUserInfo, shopIsActive, keyItem, mainItem, finishCart, setEmptyValues, emptyValues }) => {

    const dispatch = useDispatch();
    var percentProduct = 0;
    percentProduct = 100 - (parseFloat(item.anPrice) * 100 / parseFloat(item.anOldPrice));
    const [show, setShow] = useState(false);
    const [qtyValue, setQtyValue] = useState('');
    const { t } = useTranslation('loyalty');


    const now = Date.now();
    const adLoyaltyStartDate = Date.parse(loyaltySettings.adLoyaltyStartDate)
    const adLoyaltyEndDate = Date.parse(loyaltySettings.adLoyaltyEndDate)
    const loyaltyTypeId = (loyaltySettings.anLoyaltyTypeId);
    // console.log(loyaltyTypeId, "loyaltyTypeId");
    // const dummyNow = new Date(2022, 9, 26, 11, 23);
    // const parsedDummyDate = Date.parse(dummyNow)

    // console.log(loyaltySettings, 'loyaltySettings')

    const handleShow = () => {
        setShow(true);
        dispatch(getProductSpecification(item.anProductKey));
    }
    const handleClose = () => setShow(false);

    const renderElement = () => {

        if (shopIsActive == 0 && item.isPriceVisible == 1) {
            return (
                <>
                    <td className="text-align-right"><CurrencyFormat num={item.anPrice} /> <small>{t('EUR')}</small></td>
                    <td className="text-align-center">
                        <div className="percetange-loyatly-product">{parseInt(percentProduct)}<small>%</small></div>
                    </td>
                </>
            )
        } else if (shopIsActive == 1) {
            return (
                <>
                    <td className="text-align-right"><CurrencyFormat num={item.anPrice} /> <small>{t('EUR')}</small></td>
                    <td className="text-align-center">
                        <div className="percetange-loyatly-product">{parseInt(percentProduct)}<small>%</small></div>
                    </td>
                </>
            )
        } else {
            return (
                <>
                    <td className="text-align-right">{t('uskoro')}</td>
                    <td></td>
                </>
            )
        }
    };

    // console.log(item, 'item')

    const setQtyValueFunction = (name, val) => {

        if (val == "0" || (!isNumeric(val) && val != "")) {
            val = ""
            setQtyValue('');
            toast(<MessageInfoBox message="loyaltyNoZeroQty" type="0" />, {
                className: 'red-background',
            });
        } else {
            if (item.anQty < item.anQtyAvailableForCompany) {
                if (parseInt(val) > item.anQty) {
                    setQtyValue(item.anQty);
                    toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
                        className: 'red-background',
                    });
                } else {
                    setQtyValue(val);
                }
            } else {
                if (parseInt(val) > item.anQtyAvailableForCompany) {
                    setQtyValue(item.anQtyAvailableForCompany);
                    toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
                        className: 'red-background',
                    });
                } else {
                    setQtyValue(val);
                }
            }

            // if (parseInt(val) > item.anQty) {
            //     if (parseInt(val) > item.anQtyAvailableForCompany) {
            //         if (item.anQty <= item.anQtyAvailableForCompany) {

            //         }
            //         setQtyValue(item.anQtyAvailableForCompany);
            //         toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
            //             className: 'red-background',
            //         });
            //     } else {
            //         setQtyValue(item.anQty);
            //         toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
            //             className: 'red-background',
            //         });
            //     }
            // } else {
            //     setQtyValue(val);
            // }

            // if (parseInt(val) > item.anQtyAvailableForCompany) {
            //         setQtyValue(item.anQtyAvailableForCompany);
            //         toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
            //             className: 'red-background',
            //         });

            // } else {
            //     setQtyValue(val);
            // }
        }

        if (val == "") {
            let filteredArray = emptyValues.filter((item, index) => item.name !== name);
            setEmptyValues(filteredArray)
        } else {
            setEmptyValues([...emptyValues, { "name": name, "value": val }])
        }

    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the entirety of the string (`parseFloat` alone does not do this)...
            !isNaN(parseInt(str)) // ...and ensure strings of whitespace fail
    }
    //  const blockInvalidChar = e => ['e', 'E', '+', '-', '0'].includes(e.key) && e.preventDefault();

    useEffect(() => {
        setQtyValue('');
    }, [finishCart])
    // console.log(shopIsActive, "shopIsActive");
    const renderInputElement = () => {
        if (shopIsActive == 1 && getUserInfo.acCompanyLoyaltyStatus.trim().toUpperCase() != 'CRVEN' && loyaltySettings.anCompanyTarget < loyaltySettings.anCompanyTargetAchieved && (adLoyaltyStartDate <= now && adLoyaltyEndDate >= now)) {
            if (item.anQtyAvailableForCompany > 0) {
                return (
                    <input
                        type="text"
                        className="form-control loyalty-input-value"
                        data-product={item.anProductKey}
                        data-product-name={item.acProductName}
                        data-product-price={item.anPrice}
                        data-product-ident={item.acProduct}
                        data-product-id={item.anProductId}
                        value={qtyValue}
                        // onKeyDown={blockInvalidChar}
                        onChange={(e) => setQtyValueFunction(item.acProduct, e.target.value)}
                        disabled={item.anQty <= 0 || item.anQtyAvailableForCompany <= 0 && true}
                    />
                )
            } else {
                return null;
            }

        } else {
            return null;
        }


    }
    const renderInputElementTehnovacije = () => {
        if (shopIsActive == 1) {
            if (item.anQtyAvailableForCompany > 0) {
                return (
                    <input
                        type="text"
                        className="form-control loyalty-input-value"
                        data-product={item.anProductKey}
                        data-product-name={item.acProductName}
                        data-product-price={item.anPrice}
                        data-product-ident={item.acProduct}
                        data-product-id={item.anProductId}
                        value={qtyValue}
                        // onKeyDown={blockInvalidChar}
                        onChange={(e) => setQtyValueFunction(item.acProduct, e.target.value)}
                        disabled={item.anQty <= 0 || item.anQtyAvailableForCompany <= 0 && true}
                    />
                )
            } else {
                return null;
            }

        } else {
            return null;
        }


    }
    // console.log(loyaltySettings, 'shopIsActive');
    return (
        <>
            <tr className={`${keyItem == 0 && 'table-row-no-padding-top'} ${shopIsActive == 1 && item.anQty <= 0 ? 'sold-out' : item.anQtyAvailableForCompany <= 0 ? "company-limit-reached" : ""}`} key={keyItem}>
                <td>
                    <div className="productImage" style={{ width: ' 130px' }}>
                        <img src={`https://resource.ewe.rs/products/${item.acProduct}_v.jpg`} alt="" className="img-fluid img-loyalty-product" />
                    </div>
                </td>
                <td>{item.acProduct}</td>
                <td>
                    {item.isSpecialForCompany == 1 && (
                        <img src="/images/loyalty/special-for-company.png" className="special-icon-loyalty-for-company" alt="Loyalty Shop" />
                    )}
                    {item.isSaleConnected == 1 && (
                        <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja.png" />
                    )}
                    {item.acConditionType == 'PovezanaProdaja' && (
                        <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja-artikal.png" />
                    )}
                </td>
                <td className="loyalty-detail-name-click" onClick={(e) => handleShow()}>
                    {item.acProductName}
                </td>
                <td className="text-align-right">{shopIsActive == 1 ? item.anQty : item.anQtyVirtual}</td>
                <td className="text-align-right">{shopIsActive == 1 ? item.anQtyAvailableForCompany : t('racunaSe')}</td>
                <td className="text-align-right">
                    <span className="loyaltyOldPrice"><CurrencyFormat num={item.anOldPrice} /> <small>{t('EUR')}</small></span>
                </td>
                {renderElement()}
                <td>
                    {loyaltyTypeId == 1 ? renderInputElement() : loyaltyTypeId == 2 ? renderInputElementTehnovacije() : null}
                </td>
            </tr>
            <ModalLoyalty show={show} item={item} handleClose={handleClose} />
        </>
    );
};

export default LoyaltyItem;