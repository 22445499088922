import React, { useEffect, useState } from 'react';

const ScrollToTop = () => {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 300) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
    }, []);
    
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    };

    return (
      <button onClick={scrollToTop} className={`back-to-top ${showButton && 'active'}`}>
          <i className="fas fa-chevron-up"></i>
      </button>
    )
}

export default ScrollToTop;