import React, { useEffect, useState } from "react";
import ProductDetailPdf from "./templates/ProductDetailPdf";
import { PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetail } from "../../redux/actions/productActions";
import { getProductSpecification } from "../../redux/actions/productActions";
import SecureLS from 'secure-ls';

const ProductPdf = () => {

    const dispatch = useDispatch();
    const productsDetail = useSelector((state) => state.productDetail.items);
    const productsDetailSpec = useSelector((state) => state.getProductSpecification);
    let ls = new SecureLS({ encodingType: 'aes' });

    const anActiveVat = JSON.parse(localStorage.getItem('activeVat'))
    const offer = JSON.parse(ls.get('customOffer'));
    console.log(offer, 'offer');

    useEffect(() => {
        dispatch(getProductDetail(offer.productId));
        dispatch(getProductSpecification(offer.productId));
    }, []);

    if (productsDetailSpec) {
        console.log('productsDetailSpec', productsDetailSpec);
        if (!productsDetailSpec.list) {
            return null;
        }
    } else {
        console.log('no product detail spec', productsDetailSpec);
        return null
    }

    return (
        <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            {productsDetail.length > 0 && <ProductDetailPdf item={productsDetail[0]} spec={productsDetailSpec.list} custom={offer} currency={offer.currency} activeVat={anActiveVat} badges2={productsDetailSpec.filterIcons} />}
        </PDFViewer>
    );
};

export default ProductPdf;
