import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { signout } from '../../redux/actions/userActions';
import { globalStatistic } from '../../redux/actions/otherActions'
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const MenuLoginButton = ({ sidebar, userInfo }) => {

    const dispatch = useDispatch();
    const iconPath = process.env.PUBLIC_URL + '/media/';

    let ls = new SecureLS({ encodingType: 'aes' });
    var isLoggedIn = ls.get('userInfo');

    const signoutHandler = () => {
        dispatch(globalStatistic(357, 'LOGOUT'));
        dispatch(signout());
    }
    const { t } = useTranslation(['menu', 'routes']);



    return (
        <>
            {isLoggedIn ? (
                <Link to="/">
                    <div id="footer-sidebar-logout" className={`footer-sidebar-logout ${sidebar ? 'active-sidebar-logout-btn' : ''}`} onClick={signoutHandler} >
                        {sidebar ? (
                            <div>
                                {<img
                                    src={`${iconPath}icon-odjava.svg`}
                                    alt=""
                                />}
                            </div>
                        ) : (
                            <div>
                                {<img
                                    src={`${iconPath}icon-odjava.svg`}
                                    alt=""
                                />}
                                {/* <FontAwesomeIcon icon={faPowerOff} />  */}
                                <span>{t('menu:odjava')}</span>
                            </div>
                        )}
                    </div>
                </Link>
            ) : (
                    <Link id="login-button-home-page" to={`/${t('routes:prijava')}`} className="login-button-home-page">
                    <div className={`footer-sidebar-logout ${sidebar ? 'active-sidebar-logout-btn' : ''}`}>
                        {sidebar ? (
                            <div>
                                <FontAwesomeIcon icon={faSignInAlt} />
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon icon={faSignInAlt} /> <span>{t('menu:prijava')}</span>
                            </div>
                        )}
                    </div>
                </Link>
            )}
        </>
    );
};

export default MenuLoginButton;