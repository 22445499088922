
import React, { useEffect, useState } from 'react';
import { SetVatFunction } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { getSearchItemsPage, getWishList, getActionProductsList, getLastProductsList, getPopupProductDetail, fetchPostsProduct, getProductRelatedSale } from '../../redux/actions/productActions';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

function useQuery() {
    return new URLSearchParams(useLocation().search);
};

const VatMenuItem = ({ productItem, inputName }) => {
    const { t } = useTranslation(['other', 'routes']);
    let ls = new SecureLS({ encodingType: 'aes' });
    let query = useQuery();
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    // const test = JSON.parse(localStorage.getItem('activeVat'))
    const [flag, setFlag] = useState(false)
    const activeVat = useSelector((state) => state.activeVat.item);
    const getUserBanner = useSelector((state) => state.getUserBanner.items);
    const catalogItems = location.pathname.includes('kreiraj') && ls.get('catalogItems') ? ls.get('catalogItems') : "";
    let acIdents = catalogItems != "" ? catalogItems.map(i => { return i.acProduct }) : "";

    const onVatChangeFunction = (e, type) => {
        dispatch(SetVatFunction(e.target.value, location, query.toString(), acIdents));
        if (location.pathname.includes('pretraga')) {
            dispatch(getSearchItemsPage(query.get("q"), query.get("brand"), query.get("category")));
        } else if (location.pathname.includes('lista-zelja')) {
            dispatch(getWishList());
        } else if (location.pathname.includes('specijalna-akcija')) {
            var filteredUrl = location.pathname.split('/');
            dispatch(getActionProductsList(filteredUrl[3], null, acIdents));
        } else if (location.pathname.includes('pregledani-proizvodi') || location.pathname.includes('proizvod') || location.pathname.includes('производ')) {
            dispatch(getLastProductsList());
            if (location.pathname.includes('proizvod') || location.pathname.includes('производ')) {
                dispatch(getProductRelatedSale(params.productId));
            }
        } else if (location.pathname.includes(t('routes:katalog'))) {
            let pathNameArr = location.pathname.split('/')
            let categoryId = pathNameArr[pathNameArr.length - 1]
            let userBanner = getUserBanner.filter(x => x.anCategoryKey == categoryId)
            if (userBanner[0]) {
                let productId = userBanner[0].acProducts.split(',');
                dispatch(fetchPostsProduct(0, 0, productId[0]));
            }
        } else if (location.pathname == '/') {
            dispatch(getActionProductsList(4, 'weekAction'));
            dispatch(getActionProductsList(2, 'inStockAgain'));
            dispatch(getActionProductsList(5, 'recommendedForYou'));
            if (productItem) {
                dispatch(getPopupProductDetail(productItem.anProductKey));
            }
        }
    };
    const gatherStatisticSwitchDashboard = () => {
        dispatch(globalStatistic(0, 'SWITCH_DASHBOARD'));
    }

    useEffect(() => {
        if (activeVat == 1) {
            setFlag(true)
        } else {
            setFlag(false)
        }
    }, [activeVat]);



    return (
        <>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name={`priceVat${inputName}`}
                    id="priceVat1"
                    defaultValue="1"
                    checked={activeVat == 1}
                    onClick={(e) => onVatChangeFunction(e)}
                    onChange={e => { }} // warning fix
                />
                <label className="form-check-label" htmlFor="priceVat1">{t('other:da')}</label>
            </div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name={`priceVat${inputName}`}
                    id="priceVat2"
                    defaultValue="0"
                    checked={activeVat == 0}
                    onClick={(e) => { onVatChangeFunction(e); gatherStatisticSwitchDashboard() }}
                    onChange={e => { }} // warning fix
                />
                <label className="form-check-label" htmlFor="priceVat2">{t('other:ne')}</label>
            </div>
        </>
    );
};

export default VatMenuItem;