import React, { useState, useEffect } from "react";
// import imageExists  from 'image-exists';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from '../other/CurrencyFormat';
import {
    removeProductCompare
} from '../../redux/actions/productActions';

import globalVariable from "../../global";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import SecureLS from 'secure-ls';


const CompareItemTopInfo = ({ item, key }) => {

    const dispatch = useDispatch();

    const getUserSettings = useSelector((state) => state.getUserSettings);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const activeVat = useSelector((state) => state.activeVat.item);
    const getUserInfo = useSelector((state) => state.userInfo);
    // const activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    // const activeVat = JSON.parse(localStorage.getItem('activeVat'));
    const { t } = useTranslation(['catalog', 'product']);
    let ls = new SecureLS({ encodingType: 'aes' });
    const [anPrice, setAnPrice] = useState(0);
    const [anRetailPrice, setAnRetailPrice] = useState(0);
    const getB2bSettings = useSelector((state) => state.getB2bSettings);

    const addDefaultSrc = (e) => {
        e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
    };

    const removeProduct = (product) => {
        dispatch(removeProductCompare(product));
        // console.log(product, 'product');
    };

    useEffect(() => {

    }, []);
    useEffect(() => {
        try {
            const anId = item.anProductKey;
            var userInfoData = ls.get('userInfo');
            var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
            var activeVat = JSON.parse(localStorage.getItem('activeVat'));
            // console.log(userInfoData, 'userInfoData')
            const attributes = {};
            attributes['anMainCategoryId'] = 0;
            attributes['anCategoryId'] = 0;
            attributes['anSubCategoryId'] = 0;
            attributes['acBrand'] = '';
            attributes['anId'] = anId !== "" && anId !== undefined && anId !== null ? anId : 0;
            attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
            attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
            attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
            attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
            attributes['anCatalogSortingType'] = 1;
            attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
            const configRes = axiosinstance(attributes, '8224', 'getProduct');

            axios(configRes)
                .then(function (response) {
                    console.log(response, 'response');
                    if (response.data.isOk == 1) {
                        if (response.data.response != null) {
                            if (response.data.response.product.length > 0) {
                                // console.log(response.data.response.product[0], 'response.data.response.product[0]');
                                setAnPrice(response.data.response.product[0].anPrice);
                                setAnRetailPrice(response.data.response.product[0].anRetailPrice);
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } catch (error) {
            console.log(error);
        }
    }, [activeVat]);
    useEffect(() => {
        try {
            const anId = item.anProductKey;
            var userInfoData = ls.get('userInfo');
            var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
            var activeVat = JSON.parse(localStorage.getItem('activeVat'));
            // console.log(userInfoData, 'userInfoData')
            const attributes = {};
            attributes['anMainCategoryId'] = 0;
            attributes['anCategoryId'] = 0;
            attributes['anSubCategoryId'] = 0;
            attributes['acBrand'] = '';
            attributes['anId'] = anId !== "" && anId !== undefined && anId !== null ? anId : 0;
            attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
            attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
            attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
            attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
            attributes['anCatalogSortingType'] = 1;
            attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
            const configRes = axiosinstance(attributes, '8224', 'getProduct');

            axios(configRes)
                .then(function (response) {
                    console.log(response, 'response');
                    if (response.data.isOk == 1) {
                        if (response.data.response != null) {
                            if (response.data.response.product.length > 0) {
                                // console.log(response.data.response.product[0], 'response.data.response.product[0]');
                                setAnPrice(response.data.response.product[0].anPrice);
                                setAnRetailPrice(response.data.response.product[0].anRetailPrice);
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } catch (error) {
            console.log(error);
        }
    }, [activeCurrency]);
    return (
        <td key={key}>
            <img className="img-fluid" src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}.jpg`} alt={item.acName} onError={(e) => addDefaultSrc(e)} />
            <div className="compare-page-item-name" title={item.acName}>
                {item.acDept.replace(" 1****", "")} {item.acName}
            </div>
            <div className="compare-page-item-ident">{item.acProduct}</div>
            <div className="compare-page-item-price">
                <CurrencyFormat num={getUserInfo.userInfo ? anPrice : anRetailPrice} /> {getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('catalog:RSD')} {getUserInfo.userInfo ? (parseInt(activeVat) == 1 ? t('product:saPdv') : t('product:bezPdv')) : ''}
            </div>
            <div className="remove-compare-item-page" onClick={(e) => removeProduct(item)}>
                <i className="fas fa-times"></i>
            </div>
        </td>
    )
}

export default CompareItemTopInfo;