import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { getMenuItems } from '../redux/actions/menuActions';
import { signout } from '../redux/actions/userActions';
import { getCartFunction } from '../redux/actions/cartActions';
import { getCatalogMenuList } from '../redux/actions/menuActions';
import { getSearchItemsBox } from '../redux/actions/productActions';

import CategorySidebarItem from './sidebarCategory/CategorySidebarItem';
import SearchBoxModal from '../components/search/SearchBoxModal';
import SearchBox from '../components/search/SearchBox';
import { ToastContainer } from 'react-toastify';
import globalVariable from '../global';
import CurrencyMenuItem from './other/CurrencyMenuItem';
import VatMenuItem from './other/VatMenuItem';

import { removeSpecialChar } from '../helperFunctions'
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const Header = () => {

    const dispatch = useDispatch();
    const [menuItem, setMenuItem] = useState(false);
    const [name, setName] = useState('');
    const [searchText, setSearchText] = useState('');
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [searchBox, setSearchBox] = useState(0);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo);
    const menuItems = useSelector((state) => state.menuItems);
    const catalogMenuListItems = useSelector((state) => state.getCatalogMenuList);
    const getSearchItemsBoxList = useSelector((state) => state.getSearchItemsBox);
    const cartActiveDetail = useSelector((state) => state.getActiveCartFunction[0]);
    const iconPath = process.env.PUBLIC_URL + '/media/';
    let ls = new SecureLS({ encodingType: 'aes' });
    const isLoggedIn = ls.get('userInfo');;

    const { t } = useTranslation(['components', 'routes']);

    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const productItem = useSelector((state) => state.productDetail.items[0]);
    const getCurrencyItems = useSelector((state) => state.getCurrencyItems);
    const activeVat = useSelector((state) => state.activeVat.item);

    const closeDropdown = () => {
        setShowSearchBox(false)
    }
    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    let history = useHistory();
    // console.log(cartActiveDetail, 'cartActiveDetail');
    useEffect(() => {
        dispatch(getMenuItems());
        dispatch(getCartFunction());
        dispatch(getCatalogMenuList());
    }, []);

    const renderList = () => {
        return menuItems.map((item, key) => {
            return (
                <div key={key}>
                    {item.position === 1 && (
                        <>
                            {item.menu.map((value, index) => (
                                <Link to={`${value.subMenu.length > 0 ? `/meni/${value.anMenuKey}/${removeSpecialChar(value.acMenu)}` : `${value.acMenu == 'DASHBOARD' ? `/meni/${value.anMenuKey}/${removeSpecialChar(value.acMenu)}` : `${value.acLink}`}`}`} onClick={(e) => setMenuItem(false)}>
                                    <li key={index}>
                                        <li><img alt="" src={`${globalVariable.imagesPath}${value.acIcon}`} className="img-fluid" />&nbsp; {value.acMenu}

                                        </li>
                                        {/* <li><i className={value.acIcon}></i>&nbsp; {value.acMenu}
                                        
                                        </li> */}
                                    </li>
                                </Link>
                            ))}
                        </>
                    )}
                </div>
            )
        });
    };

    const setOpenMenuMobileFunction = () => {
        if (menuItem == true) {
            setMenuItem(false);
        } else {
            setMenuItem(true);
        };
        setShowSearchBox(false);
    };

    var userEmails = [];
    var stringEmail = getUserInfo && getUserInfo.company.acCompanyMail1 ? getUserInfo.company.acCompanyMail1.split(';') : ''
    if (stringEmail != '') {
        stringEmail.forEach(element => {
            var obj = {}
            obj['email'] = element;
            userEmails.push(obj);
        });
    }

    const signoutFunction = () => {
        dispatch(signout());
    }

    const renderListCategories = () => {
        var catalogItemsList = catalogMenuListItems.filter((x) => x._id !== 1 && x._id !== 12);
        return catalogItemsList.map((item, key) => (
            <CategorySidebarItem setMenuItem={setMenuItem} key={key} item={item} />
        ));
    };

    const callbackSerachFunction = () => {
        setSearchBox(0);
    };

    const searchBoxModalClosedFunciot = () => {
        setSearchBox(0);
    }


    const callbackSearchBox = (searchBoxLength, valItem) => {
        if (searchBoxLength > 0) {
            setSearchBox(1);
        } else {
            setSearchBox(0);
        };

        if (searchBoxLength > 3) {
            dispatch(getSearchItemsBox(valItem, ''));
        } else {
            setSearchBox(0);
            dispatch(getSearchItemsBox(valItem, 'clear'));
        };
    };

    const callbackSearchBoxSetModal = () => {
        // this.setState({
        //     searchBox: 1,
        // });
    };

    const setSearchNameFunctionSet = (valItem) => {
        setSearchText(valItem);
    }


    return (
        <>
            <div className={`header-content-top ${!getUserInfo && "intro-header"}`} ref={ref}>
                <div className={`menu-mobile-sidebar ${menuItem == true && 'toggle-mob-menu'}`}>
                    <div className="mobile-header-sidebar">
                        <div className="logo-mobile-header-sidebar">
                            <nav className={`navbar navbar-expand-lg navbar-dark ${!getUserInfo && "header-logo-m"}`}>
                                <h1 title="Ewe Comp">
                                    <Link className="navbar-brand" to="/" onClick={(e) => { setOpenMenuMobileFunction(); }}>
                                        {getUserInfo ?
                                            <img src={process.env.PUBLIC_URL + '/ewe-logo.svg'} className="img-fluid customImageLogo" alt="logo" />
                                            :
                                            <div className="">
                                                <svg className="ewe-logo-m customImageLogo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 286.3 91.6" xmlSpace="preserve">
                                                    <g>
                                                        <path className="st3" d="M8,42.8l40.9-23.2c-1.7-2-3.9-3.6-6.6-4.6c-2.7-1-5.7-1.6-9.1-1.6c-7.1,0-13.1,2.3-18.1,7
                                                                        c-4.9,4.7-7.4,10.4-7.4,17.2c0,1.1,0,2,0.1,2.8C7.9,41.1,7.9,42,8,42.8L8,42.8z M57.8,60.2c-4.1,2.9-8.1,5-12.1,6.4
                                                                        c-4,1.4-8.2,2-12.4,2c-9.4,0-17.2-3-23.5-8.9c-6.2-5.9-9.3-13.2-9.3-22c0-8.8,3.1-16.1,9.4-22C16.1,9.9,23.9,7,33.2,7
                                                                        c5.9,0,11.1,1.2,15.5,3.7c4.4,2.4,7.9,5.9,10.3,10.5L10.5,48.4c2.3,4.2,5.4,7.5,9.4,9.8c4,2.3,8.4,3.5,13.3,3.5
                                                                        c3.9,0,7.8-0.8,11.6-2.3c3.9-1.6,8.2-4.1,12.9-7.7V60.2L57.8,60.2z M85.6,68.5l-27-59.4l7-2.4l20.5,45.9l22.7-45.9l22.6,45.7
                                                                        l21.5-45.7l6.4,2l-28,59.8l-23-47.2L85.6,68.5L85.6,68.5z M168.3,42.8l40.9-23.2c-1.7-2-3.9-3.6-6.6-4.6c-2.6-1-5.7-1.6-9.1-1.6
                                                                        c-7.1,0-13.1,2.3-18.1,7c-4.9,4.7-7.4,10.4-7.4,17.2c0,1.1,0,2,0.1,2.8C168.2,41.1,168.2,42,168.3,42.8L168.3,42.8z M218.1,60.2
                                                                        c-4.1,2.9-8.1,5-12.1,6.4c-4,1.4-8.2,2-12.4,2c-9.4,0-17.2-3-23.5-8.9c-6.2-5.9-9.3-13.2-9.3-22c0-8.8,3.1-16.1,9.4-22
                                                                        c6.3-5.8,14.1-8.8,23.4-8.8c5.9,0,11.1,1.2,15.5,3.7c4.4,2.4,7.9,5.9,10.3,10.5l-48.6,27.3c2.3,4.2,5.4,7.5,9.4,9.8
                                                                        c4,2.3,8.4,3.5,13.3,3.5c3.9,0,7.8-0.8,11.6-2.3c3.8-1.6,8.2-4.1,12.9-7.7V60.2L218.1,60.2z"/>
                                                    </g>
                                                    <g>
                                                        <path className="st3" d="M261.7,29.1c-5-1.3-1.9,5.3,0.5,5.6c-1.9-0.1-3.7-1.2-5.1-2.7c-1.2-1.2-4.6-6.1-1.6-6.7
                                                                        C258.1,24.8,261.1,28.3,261.7,29.1z"/>
                                                        <path className="st3" d="M263.2,0.4c0.8,0,1.6,0,2.3,0c7.2,0.7,11.1,8.9,7.8,15.9c5.8,4.6,13.6,10.6,12.8,20.9
                                                                        c-0.6,8.4-6.6,12.7-14.5,14.5c0.1,1.8-0.2,4.3-1.6,5.2c3.8,1.1,4,9.2-1.4,8.2c-1.9-0.4-2.9-2.4-3.3-4.6
                                                                        c-3.2,3.3-8.1,6.2-14.8,6.5c1.1,2.7,0.1,7-3,7.6c-0.6,0-1.1,0-1.7,0c-3.1-0.7-4.9-4.8-3.3-8.2c-5.3-1.2-9.7-3.1-13.6-5.9
                                                                        c-1.1-0.8-2.1-2.6-3.9-1.9c0.3,3.3-1.7,6.6-5.1,5.9c-3.6-0.7-2.7-6.2-0.3-7.7c-2.5-1.8-2.2-7.1-0.2-9.2
                                                                        c-3.1-9.9,2.9-17.8,10.8-20c-7-6.9-3.7-22.6,8.6-22.6c4.6,0,8.1,2.4,10.3,4.9c1.3,1.4,1,2.7,3.3,1.8
                                                                        C253.7,5.7,256.8,1.5,263.2,0.4z M226.9,57.3c1.3-0.2,2.2-1.5,3.1-1.9c-0.1,0-0.2,0-0.2,0.1c1.5,1.6,3.5,2.7,5.6,3.7
                                                                        c-0.3-5.9,3.5-9.5,9.3-8.6c-10.1,0-6.4,15.6,2.5,13.1c2.7-0.8,3.9-3.2,4.1-7c0.4,2.2,0,4.5-0.8,6.1c8.4-0.4,13.7-3.7,15.6-10.4
                                                                        c-15.9-1.2-25.7-8.3-29.6-20.9c-2.2-0.8-4.2-2.4-5.8-3.3c-7.1,1.6-11.8,9.4-8.6,17.7c2.1-0.7,5.3-0.1,5.9,1
                                                                        C219.9,43.6,217.9,58.9,226.9,57.3z M264.3,3.5c-5.6-0.5-8.6,3.5-9.8,8c0.7,0,1.4,0,2,0c2.3-4.1,9.8-2.1,10.6,2.1
                                                                        c2,0.5,3.4,1.5,5.1,2.2C273,9.4,270,4.1,264.3,3.5z M240.5,9.2c-3.3-0.3-5.8,0.6-7.5,1.8c-6.7,4.9-3.1,18.6,5.1,19.2
                                                                        c2.3,0.2,3.9-0.6,4.8-1.5c3.5-3.1,0.5-8.5-3-9.7c-0.9-0.3-3.6-0.7-4.7,0.2c-3.7,2.7,2.8,8.7,4.7,4.3c-0.5,5.9-8.6,2.7-7-2.7
                                                                        c0.8-2.7,3.8-4,5.9-4.2c5-0.5,9,3.1,9.3,7.4c0.3,4.7-3.4,8.3-8.6,7.9c4.1,8,13,15,24.8,15.7c6.8,0.4,12.1-1.2,15.6-4.6
                                                                        c8.6-8.4,0.4-19.8-6.1-24.5c-6-4.4-14-7.2-22.9-5.8c0.9,2.1,1.9,4.9,1.4,7.9C250.8,14.5,246.7,9.8,240.5,9.2z M258.4,11.6
                                                                        c1.8,0.1,3.9,0.8,5.3,0.7C262.8,10.6,259.5,8.9,258.4,11.6z M268.7,52.2c-0.1,1.5-0.4,2.9-0.9,4c2-0.6,3.2-1.9,3.3-4.3
                                                                        C270.2,52,269.6,52.2,268.7,52.2z M269.9,63.4c2.8-0.4,2.1-5.2,0-6.1c-0.9,0.9-2,1.6-3.6,1.8C266.2,61.7,267.8,63.7,269.9,63.4z
                                                                        M221,62.8c2.1,0.2,3.4-2.1,3.9-4c-2.1,0.1-3.4-0.6-4.7-1.3C219.2,59.1,218.8,62.5,221,62.8z M247,72.6c3.3-0.1,4-5.2,1.9-7.4
                                                                        c-1.6,0.1-2.9,1.7-5.4,1.3C242.8,69.7,244.4,72.6,247,72.6z"/>
                                                    </g>
                                                    <g>
                                                        <path d="M4.9,91.4H4.4l-4-11.1h0.9l3.2,8.9c0.1,0.4,0.2,0.7,0.3,1h0C4.8,90,4.9,89.7,5,89.2l3.4-8.9h0.8L4.9,91.4z" />
                                                        <path d="M20.9,91.4L19.6,88h-4.8l-1.3,3.4h-0.8l4.2-11.1h0.7l4.1,11.1H20.9z M17.3,81.3L17.3,81.3c-0.1,0.3-0.2,0.6-0.3,0.8
                                                            l-1.9,5.1h4.3l-1.9-5.1L17.3,81.3z"/>
                                                        <path d="M27.7,91.4V80.4h0.8v10.4h4.4v0.7H27.7z" />
                                                        <path d="M46.3,87c0,3.1-1.3,4.6-3.9,4.6c-2.5,0-3.7-1.5-3.7-4.4v-6.8h0.8V87c0,2.6,1,3.8,3.1,3.8c2,0,3-1.2,3-3.7v-6.8h0.8V87z" />
                                                        <path d="M53.1,91.4V80.4h5.2v0.7h-4.4v4.4h4.1v0.7h-4.1v4.5h4.7v0.7H53.1z" />
                                                        <path d="M82.4,91.4h-2.7l-0.8-2.5H75l-0.8,2.5h-2.7l4-11.1h3L82.4,91.4z M78.3,87l-1.2-3.7C77,83,77,82.7,77,82.3h-0.1
                                                            c0,0.3-0.1,0.6-0.2,1L75.5,87H78.3z"/>
                                                        <path d="M97.7,85.7c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.5-2.1,2c-0.9,0.5-1.9,0.7-3.1,0.7h-3.9V80.4h3.9
                                                            C95.7,80.4,97.7,82.1,97.7,85.7z M95.1,85.8c0-1.1-0.3-1.9-0.9-2.5c-0.6-0.6-1.5-0.9-2.5-0.9h-1.2v7h1.2c1.1,0,1.9-0.3,2.5-1
                                                            C94.8,87.8,95.1,86.9,95.1,85.8z"/>
                                                        <path d="M113.4,85.7c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.5-2.1,2c-0.9,0.5-1.9,0.7-3.1,0.7h-3.9V80.4h3.9
                                                            C111.5,80.4,113.4,82.1,113.4,85.7z M110.8,85.8c0-1.1-0.3-1.9-0.9-2.5c-0.6-0.6-1.5-0.9-2.5-0.9h-1.2v7h1.2c1.1,0,1.9-0.3,2.5-1
                                                            C110.5,87.8,110.8,86.9,110.8,85.8z"/>
                                                        <path d="M126,91.4h-6.6V80.4h6.4v2h-3.9v2.5h3.6v2h-3.6v2.5h4.1V91.4z" />
                                                        <path d="M141.7,85.7c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.5-2.1,2c-0.9,0.5-1.9,0.7-3.1,0.7h-3.9V80.4h3.9
                                                            C139.7,80.4,141.7,82.1,141.7,85.7z M139,85.8c0-1.1-0.3-1.9-0.9-2.5c-0.6-0.6-1.5-0.9-2.5-0.9h-1.2v7h1.2c1.1,0,1.9-0.3,2.5-1
                                                            C138.7,87.8,139,86.9,139,85.8z"/>
                                                        <path d="M155.5,91.4V80.4h2.8c1.8,0,3.2,0.5,4.2,1.4c1,0.9,1.4,2.2,1.4,4c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.1,1.5-2,2
                                                            c-0.9,0.5-1.9,0.7-3.1,0.7H155.5z M156.3,81.1v9.7h1.7c1.6,0,2.9-0.4,3.8-1.3c0.9-0.9,1.3-2.1,1.3-3.7c0-3.1-1.6-4.7-4.9-4.7H156.3
                                                            z"/>
                                                        <path d="M170.3,91.4V80.4h0.8v11.1H170.3z" />
                                                        <path d="M177.6,91v-0.9c0.8,0.5,1.6,0.8,2.4,0.8c0.9,0,1.5-0.2,2-0.5c0.4-0.4,0.7-0.9,0.7-1.5c0-0.6-0.2-1-0.5-1.4
                                                            c-0.3-0.3-1-0.8-1.9-1.4c-1.1-0.6-1.8-1.1-2.1-1.6c-0.3-0.4-0.5-1-0.5-1.6c0-0.5,0.1-1,0.4-1.4c0.3-0.4,0.7-0.8,1.2-1
                                                            c0.5-0.2,1.1-0.4,1.7-0.4c0.7,0,1.3,0.1,2,0.3v0.8c-0.6-0.3-1.3-0.4-2.1-0.4c-0.7,0-1.3,0.2-1.8,0.6c-0.5,0.4-0.7,0.9-0.7,1.5
                                                            c0,0.4,0.1,0.7,0.2,1c0.1,0.2,0.3,0.5,0.6,0.7c0.3,0.2,0.8,0.6,1.6,1.1c0.7,0.4,1.2,0.7,1.5,1c0.4,0.3,0.6,0.6,0.8,0.9
                                                            c0.2,0.3,0.2,0.7,0.2,1.2c0,0.9-0.3,1.6-0.9,2.1c-0.6,0.5-1.4,0.8-2.5,0.8c-0.3,0-0.8-0.1-1.3-0.2C178.3,91.3,177.9,91.2,177.6,91z
                                                            "/>
                                                        <path d="M192.9,81.1v10.4h-0.8V81.1H189v-0.7h7.1v0.7H192.9z" />
                                                        <path d="M207.8,91.4l-1.5-3.2c-0.3-0.6-0.6-1.1-0.9-1.3c-0.3-0.2-0.7-0.4-1.2-0.4h-1.4v4.9h-0.8V80.4h3.1c0.9,0,1.7,0.3,2.3,0.8
                                                            c0.6,0.5,0.9,1.2,0.9,2.1c0,0.8-0.2,1.5-0.7,2c-0.5,0.5-1.1,0.9-1.9,1v0c0.4,0.2,0.9,0.7,1.3,1.6l1.6,3.5H207.8z M202.9,81.1v4.7
                                                            h1.9c0.9,0,1.5-0.2,2-0.7c0.5-0.4,0.7-1.1,0.7-1.8c0-0.7-0.2-1.3-0.7-1.7c-0.4-0.4-1.1-0.6-1.9-0.6H202.9z"/>
                                                        <path d="M215,91.4V80.4h0.8v11.1H215z" />
                                                        <path d="M222.7,91.4V80.4h2.9c0.8,0,1.5,0.2,2.1,0.7c0.5,0.5,0.8,1.1,0.8,1.9c0,0.6-0.2,1.2-0.5,1.7c-0.3,0.5-0.8,0.8-1.4,1v0
                                                            c0.8,0.1,1.4,0.3,1.8,0.8c0.4,0.5,0.7,1.1,0.7,1.8c0,0.9-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9H222.7z M223.4,81.1v4.3h1.5
                                                            c0.8,0,1.5-0.2,1.9-0.6c0.5-0.4,0.7-1,0.7-1.7c0-1.3-0.8-2-2.4-2H223.4z M223.4,86v4.7h2c0.9,0,1.5-0.2,2-0.6
                                                            c0.5-0.4,0.7-1,0.7-1.8c0-1.5-1-2.3-3.1-2.3H223.4z"/>
                                                        <path d="M242.9,87c0,3.1-1.3,4.6-3.9,4.6c-2.5,0-3.7-1.5-3.7-4.4v-6.8h0.8V87c0,2.6,1,3.8,3.1,3.8c2,0,3-1.2,3-3.7v-6.8h0.8V87z" />
                                                        <path d="M252.7,81.1v10.4h-0.8V81.1h-3.2v-0.7h7.1v0.7H252.7z" />
                                                        <path d="M265.4,91.6c-1,0-1.9-0.2-2.7-0.7c-0.8-0.5-1.4-1.1-1.8-2c-0.4-0.9-0.6-1.8-0.6-2.9c0-1.2,0.2-2.2,0.7-3.1
                                                            c0.4-0.9,1.1-1.6,1.9-2c0.8-0.5,1.8-0.7,2.8-0.7c1,0,1.8,0.2,2.6,0.7c0.8,0.5,1.4,1.1,1.8,2c0.4,0.8,0.6,1.8,0.6,2.9
                                                            c0,1.2-0.2,2.2-0.6,3.1c-0.4,0.9-1,1.6-1.8,2.1C267.3,91.4,266.4,91.6,265.4,91.6z M265.4,80.9c-0.8,0-1.6,0.2-2.3,0.6
                                                            c-0.7,0.4-1.2,1-1.6,1.8c-0.4,0.8-0.6,1.6-0.6,2.6c0,1,0.2,1.9,0.5,2.6c0.4,0.8,0.9,1.3,1.5,1.8c0.7,0.4,1.4,0.6,2.3,0.6
                                                            c1.4,0,2.5-0.5,3.3-1.4c0.8-0.9,1.2-2.1,1.2-3.7c0-1-0.2-1.9-0.5-2.7c-0.3-0.8-0.9-1.3-1.5-1.7C267.1,81.1,266.3,80.9,265.4,80.9z"
                                                        />
                                                        <path d="M282.6,91.4l-1.5-3.2c-0.3-0.6-0.6-1.1-0.9-1.3c-0.3-0.2-0.7-0.4-1.2-0.4h-1.4v4.9h-0.8V80.4h3.1c0.9,0,1.7,0.3,2.3,0.8
                                                            c0.6,0.5,0.9,1.2,0.9,2.1c0,0.8-0.2,1.5-0.7,2c-0.5,0.5-1.1,0.9-1.9,1v0c0.4,0.2,0.9,0.7,1.3,1.6l1.6,3.5H282.6z M277.7,81.1v4.7
                                                            h1.9c0.9,0,1.5-0.2,2-0.7c0.5-0.4,0.7-1.1,0.7-1.8c0-0.7-0.2-1.3-0.7-1.7c-0.4-0.4-1.1-0.6-1.9-0.6H277.7z"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        }
                                    </Link>
                                </h1>
                            </nav>
                        </div>
                    </div>
                    <div className="container-fluid mobile-content-menu">
                        <div className="row">
                            <div className="col-sm-12" style={{ backgroundColor: "#2d2d2f" }}>
                                {getUserInfo && getUserInfo.isOk == 1 ? (
                                    <div className='content-mobile-menu-wrapper'>

                                        <div className="content-mobile-welcome">
                                            <span>{t('components:dobrodosli')} <b>{getUserInfo.company.acCompany}</b></span>
                                        </div>
                                        <div className="content-mobile-user-info">
                                            <div className="content-user-info-image">
                                                {getUserInfo.company.clerkResponsible['0'].acPicture == '' ? (
                                                    <div className="info-image-sidebar-mobile" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/no-user-image.png'})` }}></div>
                                                ) : (
                                                    <div className="info-image-sidebar-mobile" style={{ backgroundImage: `url(https://resource.ewe.rs/images/hr/avatars/${getUserInfo.company.clerkResponsible['0'].acPicture})` }}></div>
                                                )}
                                            </div>
                                            <div className="info-user-detail-sidebar-mobile">
                                                <div className="user-info-sector-mobile">{getUserInfo.company.clerkResponsible['0'].acJobPosition}</div>
                                                <div className="loggedin-username">{getUserInfo.company.clerkResponsible['0'].acFirstName} {getUserInfo.company.clerkResponsible['0'].acLastName}</div>
                                                <div className="user-info-email-mobile-content">
                                                    {userEmails.map((itemVal, key) => (
                                                        <div className="user-info-email-mobile">
                                                            <Link to={'/profil'} onClick={(e) => setMenuItem(false)}>{itemVal.email}</Link>
                                                            {/* <a href={itemVal.email}>{itemVal.email}</a> */}
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="social-contact-user-box">
                                                    <a href={`tel:${getUserInfo.company.clerkResponsible['0'].acPhone2}`}>
                                                        <div className="social-contact-user-box-item">
                                                            {<img
                                                                src={`${iconPath}icon-korisnik-kontakt.svg`}
                                                                alt="korisnik-kontakt-icon"
                                                            />}
                                                        </div>
                                                    </a>
                                                    <a href="mailto:info@ewe.rs">
                                                        <div className="social-contact-user-box-item">
                                                            {<img
                                                                src={`${iconPath}icon-korisnik-mejl.svg`}
                                                                alt="korisnik-mejl-icon"
                                                            />}
                                                        </div>
                                                    </a>
                                                    {getUserInfo.company.clerkResponsible['0'].acSkype && (
                                                        <a href={`skype:${getUserInfo.company.clerkResponsible['0'].acSkype}?chat`}>
                                                            <div className="social-contact-user-box-item">
                                                                <i className="fab fa-skype" style={{ color: '#0078d4' }}></i>
                                                            </div>
                                                        </a>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="menu-list-sidebar-mobile">
                                            <ul>
                                                {renderList()}
                                                <Link to="/">
                                                    <li onClick={(e) => { signoutFunction(); setOpenMenuMobileFunction(); }}><i className="far fa-share-square"></i>&nbsp; {t('components:odjava')}</li>
                                                </Link>
                                            </ul>
                                        </div>
                                    </div>
                                )
                                    : (
                                        <div className="menu-list-sidebar-mobile">
                                            <ul className="header-menu">
                                                <div>
                                                    <li><Link to="/" onClick={(e) => setMenuItem(false)}><i className="fa fa-home" aria-hidden="true"></i> {t('components:pocetna')}</Link></li>
                                                    <li><Link to={`/${t('routes:prijava')}`} onClick={(e) => setMenuItem(false)} style={{ fontWeight: '700' }}><i className="fas fa-sign-in-alt"></i> {t('components:B2B')}</Link></li>
                                                    <li><Link to="/registracija" onClick={(e) => setMenuItem(false)}><i className="fas fa-unlock"></i> {t('components:regitracija')}</Link></li>
                                                    <li><Link to="/" onClick={(e) => setMenuItem(false)}>{t('components:oNama')}</Link></li>
                                                    <li><Link to={`/${t('routes:brendovi')}`} onClick={(e) => setMenuItem(false)}>{t('components:brendovi')}</Link></li>
                                                    <li><Link to={`/${t('routes:proizvodi')}`} onClick={(e) => setMenuItem(false)}>{t('components:proizvodi')}</Link></li>
                                                    <li><a href={`/${t('routes:intro')}#Contact`} onClick={(e) => setMenuItem(false)}>{t('components:kontakt')}</a></li>
                                                    <li><Link to="/humanost-ewe" onClick={(e) => setMenuItem(false)}><img src={`${globalVariable.domainName}/gui/humanity.png`} alt="ewe" className="img-responsive img-fluid" style={{ width: '105px' }} /></Link></li>
                                                    <li><a href="/uslovi-koriscenja" onClick={(e) => setMenuItem(false)}>{t('components:usloviKoriscenja')}</a></li>
                                                    <li><a href="/politika-privatnosti" onClick={(e) => setMenuItem(false)}>{t('components:politivaPriv')}</a></li>
                                                    {/* <li><span className="language-ewe">SR<span>/</span><a href="en">EN</a></span></li> */}
                                                </div>
                                            </ul>
                                        </div>
                                    )}
                            </div>
                            <div className='col-sm-12'>
                                <div className="catalog-list-sidebar-mobile">
                                    {isLoggedIn &&
                                        <div className="currency-vat-switcher-mobile">
                                            <div className="dashboard-value-item">
                                                <div className="cart-modal-item-radio-name">
                                                    {t('components:valuta')}
                                                </div>
                                                <div className="dashboard-item-radio-buttons">
                                                    {getCurrencyItems.map((item, key) => (
                                                        <CurrencyMenuItem key={key} item={item} isPriceInEur={activeCurrency._id} productItem={productItem} inputName={'3'} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="dashboard-value-price">
                                                <div className="cart-modal-item-radio-name">
                                                    {t('components:ceneIpdv')}
                                                </div>
                                                <div className="dashboard-item-radio-buttons">
                                                    <VatMenuItem productItem={productItem} activeVat={activeVat} inputName={'3'} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="title-catalog-sidebar">{t('components:kategorije')}</div>
                                    <ul className="menu-items">
                                        {menuItem && renderListCategories()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className={`navbar navbar-expand-lg navbar-dark mobile-header-spec top-navigation-mobile ${!getUserInfo && "header-logo-m"}`}>
                    <Link className="navbar-brand" to="/" onClick={(e) => setShowSearchBox(false)}>
                        {getUserInfo ?
                            <img src={process.env.PUBLIC_URL + '/ewe-logo.svg'} className="img-fluid customImageLogo" alt="logo" />
                            :
                            <div className="">
                                <svg className="ewe-logo-m customImageLogo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 286.3 91.6" xmlSpace="preserve">
                                    <g>
                                        <path className="st3" d="M8,42.8l40.9-23.2c-1.7-2-3.9-3.6-6.6-4.6c-2.7-1-5.7-1.6-9.1-1.6c-7.1,0-13.1,2.3-18.1,7
                                                                    c-4.9,4.7-7.4,10.4-7.4,17.2c0,1.1,0,2,0.1,2.8C7.9,41.1,7.9,42,8,42.8L8,42.8z M57.8,60.2c-4.1,2.9-8.1,5-12.1,6.4
                                                                    c-4,1.4-8.2,2-12.4,2c-9.4,0-17.2-3-23.5-8.9c-6.2-5.9-9.3-13.2-9.3-22c0-8.8,3.1-16.1,9.4-22C16.1,9.9,23.9,7,33.2,7
                                                                    c5.9,0,11.1,1.2,15.5,3.7c4.4,2.4,7.9,5.9,10.3,10.5L10.5,48.4c2.3,4.2,5.4,7.5,9.4,9.8c4,2.3,8.4,3.5,13.3,3.5
                                                                    c3.9,0,7.8-0.8,11.6-2.3c3.9-1.6,8.2-4.1,12.9-7.7V60.2L57.8,60.2z M85.6,68.5l-27-59.4l7-2.4l20.5,45.9l22.7-45.9l22.6,45.7
                                                                    l21.5-45.7l6.4,2l-28,59.8l-23-47.2L85.6,68.5L85.6,68.5z M168.3,42.8l40.9-23.2c-1.7-2-3.9-3.6-6.6-4.6c-2.6-1-5.7-1.6-9.1-1.6
                                                                    c-7.1,0-13.1,2.3-18.1,7c-4.9,4.7-7.4,10.4-7.4,17.2c0,1.1,0,2,0.1,2.8C168.2,41.1,168.2,42,168.3,42.8L168.3,42.8z M218.1,60.2
                                                                    c-4.1,2.9-8.1,5-12.1,6.4c-4,1.4-8.2,2-12.4,2c-9.4,0-17.2-3-23.5-8.9c-6.2-5.9-9.3-13.2-9.3-22c0-8.8,3.1-16.1,9.4-22
                                                                    c6.3-5.8,14.1-8.8,23.4-8.8c5.9,0,11.1,1.2,15.5,3.7c4.4,2.4,7.9,5.9,10.3,10.5l-48.6,27.3c2.3,4.2,5.4,7.5,9.4,9.8
                                                                    c4,2.3,8.4,3.5,13.3,3.5c3.9,0,7.8-0.8,11.6-2.3c3.8-1.6,8.2-4.1,12.9-7.7V60.2L218.1,60.2z"/>
                                    </g>
                                    <g>
                                        <path className="st3" d="M261.7,29.1c-5-1.3-1.9,5.3,0.5,5.6c-1.9-0.1-3.7-1.2-5.1-2.7c-1.2-1.2-4.6-6.1-1.6-6.7
                                                                    C258.1,24.8,261.1,28.3,261.7,29.1z"/>
                                        <path className="st3" d="M263.2,0.4c0.8,0,1.6,0,2.3,0c7.2,0.7,11.1,8.9,7.8,15.9c5.8,4.6,13.6,10.6,12.8,20.9
                                                                    c-0.6,8.4-6.6,12.7-14.5,14.5c0.1,1.8-0.2,4.3-1.6,5.2c3.8,1.1,4,9.2-1.4,8.2c-1.9-0.4-2.9-2.4-3.3-4.6
                                                                    c-3.2,3.3-8.1,6.2-14.8,6.5c1.1,2.7,0.1,7-3,7.6c-0.6,0-1.1,0-1.7,0c-3.1-0.7-4.9-4.8-3.3-8.2c-5.3-1.2-9.7-3.1-13.6-5.9
                                                                    c-1.1-0.8-2.1-2.6-3.9-1.9c0.3,3.3-1.7,6.6-5.1,5.9c-3.6-0.7-2.7-6.2-0.3-7.7c-2.5-1.8-2.2-7.1-0.2-9.2
                                                                    c-3.1-9.9,2.9-17.8,10.8-20c-7-6.9-3.7-22.6,8.6-22.6c4.6,0,8.1,2.4,10.3,4.9c1.3,1.4,1,2.7,3.3,1.8
                                                                    C253.7,5.7,256.8,1.5,263.2,0.4z M226.9,57.3c1.3-0.2,2.2-1.5,3.1-1.9c-0.1,0-0.2,0-0.2,0.1c1.5,1.6,3.5,2.7,5.6,3.7
                                                                    c-0.3-5.9,3.5-9.5,9.3-8.6c-10.1,0-6.4,15.6,2.5,13.1c2.7-0.8,3.9-3.2,4.1-7c0.4,2.2,0,4.5-0.8,6.1c8.4-0.4,13.7-3.7,15.6-10.4
                                                                    c-15.9-1.2-25.7-8.3-29.6-20.9c-2.2-0.8-4.2-2.4-5.8-3.3c-7.1,1.6-11.8,9.4-8.6,17.7c2.1-0.7,5.3-0.1,5.9,1
                                                                    C219.9,43.6,217.9,58.9,226.9,57.3z M264.3,3.5c-5.6-0.5-8.6,3.5-9.8,8c0.7,0,1.4,0,2,0c2.3-4.1,9.8-2.1,10.6,2.1
                                                                    c2,0.5,3.4,1.5,5.1,2.2C273,9.4,270,4.1,264.3,3.5z M240.5,9.2c-3.3-0.3-5.8,0.6-7.5,1.8c-6.7,4.9-3.1,18.6,5.1,19.2
                                                                    c2.3,0.2,3.9-0.6,4.8-1.5c3.5-3.1,0.5-8.5-3-9.7c-0.9-0.3-3.6-0.7-4.7,0.2c-3.7,2.7,2.8,8.7,4.7,4.3c-0.5,5.9-8.6,2.7-7-2.7
                                                                    c0.8-2.7,3.8-4,5.9-4.2c5-0.5,9,3.1,9.3,7.4c0.3,4.7-3.4,8.3-8.6,7.9c4.1,8,13,15,24.8,15.7c6.8,0.4,12.1-1.2,15.6-4.6
                                                                    c8.6-8.4,0.4-19.8-6.1-24.5c-6-4.4-14-7.2-22.9-5.8c0.9,2.1,1.9,4.9,1.4,7.9C250.8,14.5,246.7,9.8,240.5,9.2z M258.4,11.6
                                                                    c1.8,0.1,3.9,0.8,5.3,0.7C262.8,10.6,259.5,8.9,258.4,11.6z M268.7,52.2c-0.1,1.5-0.4,2.9-0.9,4c2-0.6,3.2-1.9,3.3-4.3
                                                                    C270.2,52,269.6,52.2,268.7,52.2z M269.9,63.4c2.8-0.4,2.1-5.2,0-6.1c-0.9,0.9-2,1.6-3.6,1.8C266.2,61.7,267.8,63.7,269.9,63.4z
                                                                    M221,62.8c2.1,0.2,3.4-2.1,3.9-4c-2.1,0.1-3.4-0.6-4.7-1.3C219.2,59.1,218.8,62.5,221,62.8z M247,72.6c3.3-0.1,4-5.2,1.9-7.4
                                                                    c-1.6,0.1-2.9,1.7-5.4,1.3C242.8,69.7,244.4,72.6,247,72.6z"/>
                                    </g>
                                    <g>
                                        <path d="M4.9,91.4H4.4l-4-11.1h0.9l3.2,8.9c0.1,0.4,0.2,0.7,0.3,1h0C4.8,90,4.9,89.7,5,89.2l3.4-8.9h0.8L4.9,91.4z" />
                                        <path d="M20.9,91.4L19.6,88h-4.8l-1.3,3.4h-0.8l4.2-11.1h0.7l4.1,11.1H20.9z M17.3,81.3L17.3,81.3c-0.1,0.3-0.2,0.6-0.3,0.8
                                                        l-1.9,5.1h4.3l-1.9-5.1L17.3,81.3z"/>
                                        <path d="M27.7,91.4V80.4h0.8v10.4h4.4v0.7H27.7z" />
                                        <path d="M46.3,87c0,3.1-1.3,4.6-3.9,4.6c-2.5,0-3.7-1.5-3.7-4.4v-6.8h0.8V87c0,2.6,1,3.8,3.1,3.8c2,0,3-1.2,3-3.7v-6.8h0.8V87z" />
                                        <path d="M53.1,91.4V80.4h5.2v0.7h-4.4v4.4h4.1v0.7h-4.1v4.5h4.7v0.7H53.1z" />
                                        <path d="M82.4,91.4h-2.7l-0.8-2.5H75l-0.8,2.5h-2.7l4-11.1h3L82.4,91.4z M78.3,87l-1.2-3.7C77,83,77,82.7,77,82.3h-0.1
                                                        c0,0.3-0.1,0.6-0.2,1L75.5,87H78.3z"/>
                                        <path d="M97.7,85.7c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.5-2.1,2c-0.9,0.5-1.9,0.7-3.1,0.7h-3.9V80.4h3.9
                                                        C95.7,80.4,97.7,82.1,97.7,85.7z M95.1,85.8c0-1.1-0.3-1.9-0.9-2.5c-0.6-0.6-1.5-0.9-2.5-0.9h-1.2v7h1.2c1.1,0,1.9-0.3,2.5-1
                                                        C94.8,87.8,95.1,86.9,95.1,85.8z"/>
                                        <path d="M113.4,85.7c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.5-2.1,2c-0.9,0.5-1.9,0.7-3.1,0.7h-3.9V80.4h3.9
                                                        C111.5,80.4,113.4,82.1,113.4,85.7z M110.8,85.8c0-1.1-0.3-1.9-0.9-2.5c-0.6-0.6-1.5-0.9-2.5-0.9h-1.2v7h1.2c1.1,0,1.9-0.3,2.5-1
                                                        C110.5,87.8,110.8,86.9,110.8,85.8z"/>
                                        <path d="M126,91.4h-6.6V80.4h6.4v2h-3.9v2.5h3.6v2h-3.6v2.5h4.1V91.4z" />
                                        <path d="M141.7,85.7c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.5-2.1,2c-0.9,0.5-1.9,0.7-3.1,0.7h-3.9V80.4h3.9
                                                        C139.7,80.4,141.7,82.1,141.7,85.7z M139,85.8c0-1.1-0.3-1.9-0.9-2.5c-0.6-0.6-1.5-0.9-2.5-0.9h-1.2v7h1.2c1.1,0,1.9-0.3,2.5-1
                                                        C138.7,87.8,139,86.9,139,85.8z"/>
                                        <path d="M155.5,91.4V80.4h2.8c1.8,0,3.2,0.5,4.2,1.4c1,0.9,1.4,2.2,1.4,4c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.1,1.5-2,2
                                                        c-0.9,0.5-1.9,0.7-3.1,0.7H155.5z M156.3,81.1v9.7h1.7c1.6,0,2.9-0.4,3.8-1.3c0.9-0.9,1.3-2.1,1.3-3.7c0-3.1-1.6-4.7-4.9-4.7H156.3
                                                        z"/>
                                        <path d="M170.3,91.4V80.4h0.8v11.1H170.3z" />
                                        <path d="M177.6,91v-0.9c0.8,0.5,1.6,0.8,2.4,0.8c0.9,0,1.5-0.2,2-0.5c0.4-0.4,0.7-0.9,0.7-1.5c0-0.6-0.2-1-0.5-1.4
                                                        c-0.3-0.3-1-0.8-1.9-1.4c-1.1-0.6-1.8-1.1-2.1-1.6c-0.3-0.4-0.5-1-0.5-1.6c0-0.5,0.1-1,0.4-1.4c0.3-0.4,0.7-0.8,1.2-1
                                                        c0.5-0.2,1.1-0.4,1.7-0.4c0.7,0,1.3,0.1,2,0.3v0.8c-0.6-0.3-1.3-0.4-2.1-0.4c-0.7,0-1.3,0.2-1.8,0.6c-0.5,0.4-0.7,0.9-0.7,1.5
                                                        c0,0.4,0.1,0.7,0.2,1c0.1,0.2,0.3,0.5,0.6,0.7c0.3,0.2,0.8,0.6,1.6,1.1c0.7,0.4,1.2,0.7,1.5,1c0.4,0.3,0.6,0.6,0.8,0.9
                                                        c0.2,0.3,0.2,0.7,0.2,1.2c0,0.9-0.3,1.6-0.9,2.1c-0.6,0.5-1.4,0.8-2.5,0.8c-0.3,0-0.8-0.1-1.3-0.2C178.3,91.3,177.9,91.2,177.6,91z
                                                        "/>
                                        <path d="M192.9,81.1v10.4h-0.8V81.1H189v-0.7h7.1v0.7H192.9z" />
                                        <path d="M207.8,91.4l-1.5-3.2c-0.3-0.6-0.6-1.1-0.9-1.3c-0.3-0.2-0.7-0.4-1.2-0.4h-1.4v4.9h-0.8V80.4h3.1c0.9,0,1.7,0.3,2.3,0.8
                                                        c0.6,0.5,0.9,1.2,0.9,2.1c0,0.8-0.2,1.5-0.7,2c-0.5,0.5-1.1,0.9-1.9,1v0c0.4,0.2,0.9,0.7,1.3,1.6l1.6,3.5H207.8z M202.9,81.1v4.7
                                                        h1.9c0.9,0,1.5-0.2,2-0.7c0.5-0.4,0.7-1.1,0.7-1.8c0-0.7-0.2-1.3-0.7-1.7c-0.4-0.4-1.1-0.6-1.9-0.6H202.9z"/>
                                        <path d="M215,91.4V80.4h0.8v11.1H215z" />
                                        <path d="M222.7,91.4V80.4h2.9c0.8,0,1.5,0.2,2.1,0.7c0.5,0.5,0.8,1.1,0.8,1.9c0,0.6-0.2,1.2-0.5,1.7c-0.3,0.5-0.8,0.8-1.4,1v0
                                                        c0.8,0.1,1.4,0.3,1.8,0.8c0.4,0.5,0.7,1.1,0.7,1.8c0,0.9-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9H222.7z M223.4,81.1v4.3h1.5
                                                        c0.8,0,1.5-0.2,1.9-0.6c0.5-0.4,0.7-1,0.7-1.7c0-1.3-0.8-2-2.4-2H223.4z M223.4,86v4.7h2c0.9,0,1.5-0.2,2-0.6
                                                        c0.5-0.4,0.7-1,0.7-1.8c0-1.5-1-2.3-3.1-2.3H223.4z"/>
                                        <path d="M242.9,87c0,3.1-1.3,4.6-3.9,4.6c-2.5,0-3.7-1.5-3.7-4.4v-6.8h0.8V87c0,2.6,1,3.8,3.1,3.8c2,0,3-1.2,3-3.7v-6.8h0.8V87z" />
                                        <path d="M252.7,81.1v10.4h-0.8V81.1h-3.2v-0.7h7.1v0.7H252.7z" />
                                        <path d="M265.4,91.6c-1,0-1.9-0.2-2.7-0.7c-0.8-0.5-1.4-1.1-1.8-2c-0.4-0.9-0.6-1.8-0.6-2.9c0-1.2,0.2-2.2,0.7-3.1
                                                        c0.4-0.9,1.1-1.6,1.9-2c0.8-0.5,1.8-0.7,2.8-0.7c1,0,1.8,0.2,2.6,0.7c0.8,0.5,1.4,1.1,1.8,2c0.4,0.8,0.6,1.8,0.6,2.9
                                                        c0,1.2-0.2,2.2-0.6,3.1c-0.4,0.9-1,1.6-1.8,2.1C267.3,91.4,266.4,91.6,265.4,91.6z M265.4,80.9c-0.8,0-1.6,0.2-2.3,0.6
                                                        c-0.7,0.4-1.2,1-1.6,1.8c-0.4,0.8-0.6,1.6-0.6,2.6c0,1,0.2,1.9,0.5,2.6c0.4,0.8,0.9,1.3,1.5,1.8c0.7,0.4,1.4,0.6,2.3,0.6
                                                        c1.4,0,2.5-0.5,3.3-1.4c0.8-0.9,1.2-2.1,1.2-3.7c0-1-0.2-1.9-0.5-2.7c-0.3-0.8-0.9-1.3-1.5-1.7C267.1,81.1,266.3,80.9,265.4,80.9z"
                                        />
                                        <path d="M282.6,91.4l-1.5-3.2c-0.3-0.6-0.6-1.1-0.9-1.3c-0.3-0.2-0.7-0.4-1.2-0.4h-1.4v4.9h-0.8V80.4h3.1c0.9,0,1.7,0.3,2.3,0.8
                                                        c0.6,0.5,0.9,1.2,0.9,2.1c0,0.8-0.2,1.5-0.7,2c-0.5,0.5-1.1,0.9-1.9,1v0c0.4,0.2,0.9,0.7,1.3,1.6l1.6,3.5H282.6z M277.7,81.1v4.7
                                                        h1.9c0.9,0,1.5-0.2,2-0.7c0.5-0.4,0.7-1.1,0.7-1.8c0-0.7-0.2-1.3-0.7-1.7c-0.4-0.4-1.1-0.6-1.9-0.6H277.7z"/>
                                    </g>
                                </svg>
                            </div>
                        }
                    </Link>
                    <div className="cart-shop-icon-search-mobile" onClick={(e) => setShowSearchBox(!showSearchBox)}>
                        <i className="fas fa-search"></i>
                    </div>
                    {getUserInfo && getUserInfo.isOk ? (
                        <>
                            <Link to="/lista-zelja" onClick={(e) => setShowSearchBox(false)}>
                                <div className="cart-shop-icon-favorite">
                                    {<img
                                        src={`${iconPath}icon-lista-zelja.svg`}
                                        alt=""
                                    />}
                                </div>
                            </Link>
                            <Link to={`/${t('routes:korpa')}/${cartActiveDetail._id}`} onClick={(e) => setShowSearchBox(false)}>
                                <div className="cart-shop-icon">
                                    {<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.33 43.28">
                                        <g data-name="Layer 2">
                                            <g data-name="Layer 1">
                                                <path d="M45.42 9.66a6 6 0 011.28 1.28l2.85-2.07a9.15 9.15 0 00-2.06-2.06zm5.91 10.72c0-2.79 0-5-.19-6.81a9.38 9.38 0 00-1.59-4.7l-2.85 2.07a6 6 0 01.94 3c.16 1.55.17 3.56.17 6.43zM36 8.55c2.87 0 4.88 0 6.43.17a6 6 0 013 .94l2.07-2.85a9.38 9.38 0 00-4.7-1.59C41 5 38.77 5 36 5zm10.7 21.77a5.55 5.55 0 01-1.28 1.28l2.07 2.85a9.15 9.15 0 002.06-2.06zM36 36.23c2.79 0 5 0 6.81-.19a9.29 9.29 0 004.7-1.59l-2.09-2.85a5.9 5.9 0 01-3 .94c-1.55.17-3.56.17-6.43.17zm11.81-15.35c0 2.87 0 4.88-.17 6.43a6 6 0 01-.94 3l2.85 2.07a9.38 9.38 0 001.59-4.7c.19-1.78.19-4 .19-6.81zM17.54 31.77a6 6 0 01-1.25-1.1l-2.69 2.27a9.26 9.26 0 002 1.78zm-6.74-9c.31 2.47.56 4.45.92 6a9.52 9.52 0 001.88 4.12l2.69-2.27A5.91 5.91 0 0115.16 28c-.32-1.36-.54-3.13-.86-5.67zM26 32.71c-2.56 0-4.35 0-5.73-.14a5.92 5.92 0 01-2.76-.8l-1.92 2.95a9.32 9.32 0 004.32 1.35c1.61.16 3.6.16 6.09.16zM14.3 22.36l-2-15.79L8.82 7l2 15.79zM36 32.71H26v3.52h10zm11.81-12.33v.5h3.52v-.5zM10.57 8.55H36V5H10.57zm37.56 1.36l-1.43 1zm-1.67-1.67l-1 1.42zm0 24.79l1 1.42zm1.67-1.67l-1.43-1zM15 31.8l-1.4 1.14zm1.63 1.44l1-1.47zm-6-26.45V5h-2l.25 2z" className="cls-1"></path>
                                                <path d="M10.29 2.08A4.26 4.26 0 006.63 0v3.52a.74.74 0 01.64.37zM6.63 0H1.76v3.52h4.87zm6.71 7.15l-3.05-5.07-3 1.81L10.32 9zM1.76 0a1.76 1.76 0 000 3.52z" className="cls-1"></path>
                                                <circle cx="40.76" cy="40.76" r="2.52" className="cls-1"></circle>
                                                <circle cx="23.15" cy="40.76" r="2.52" className="cls-1"></circle>
                                                <path d="M40.26 16.86h-6.54v3.52h6.54zm-6.54 0a1.76 1.76 0 100 3.52zm6.54 3.52a1.76 1.76 0 100-3.52z" className="cls-1"></path>
                                            </g>
                                        </g>
                                    </svg>}
                                    {cartActiveDetail.cartItemsList && (
                                        <span>{cartActiveDetail.cartItemsList.length}</span>
                                    )}
                                </div>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to={`/${t('routes:prijava')}`} onClick={(e) => setShowSearchBox(false)}>
                                <div className="cart-shop-icon-user-login">
                                    <i className="far fa-user"></i>
                                </div>
                            </Link>
                        </>
                    )}
                    <div className={`menu-open-modal ${menuItem == true && 'opened-sidebar-mobile'}`} onClick={(e) => setOpenMenuMobileFunction()}>
                        <span className="menu-item-icon bar1"></span>
                        <span className="menu-item-icon bar2"></span>
                        <span className="menu-item-icon bar3"></span>
                    </div>
                </nav>
                <nav
                    className={`navbar navbar-expand-lg navbar-dark mobile-header-spec bottom-navigation-mobile mobile-search-box ${showSearchBox && 'active'}`}
                >
                    <div className="search-mobile-box">
                        <Route render={({ history }) => <SearchBox
                            closeSearchModalFunction={callbackSerachFunction}
                            setShowSearchBox={setShowSearchBox}
                            searchFunctionClick={callbackSearchBoxSetModal}
                            searchBoxFunction={callbackSearchBox}
                            history={history}
                            setSearchNameFunction={setSearchNameFunctionSet}
                            searchText={searchText}
                        ></SearchBox>}></Route>
                    </div>
                </nav>
            </div>

            <SearchBoxModal searchText={searchText} items={getSearchItemsBoxList} searchBoxModalFunction={callbackSerachFunction} searchBoxModal={searchBox} searchBoxModalClosed={searchBoxModalClosedFunciot} />
            <ToastContainer autoClose={2500} />
        </>
    )
};

export default Header;