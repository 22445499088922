import React from 'react';
import CurrencyFormat from '../other/CurrencyFormat';
import { currencyFormat } from "../../helperFunctions";

const DashboardBoxInfo = ({ data, user, item }) => {

  const detailItem = data.tmbeList;

  const title = detailItem.filter((state) => state.acMenuBlockElement === 'title');
  const value = detailItem.filter((state) => state.acMenuBlockElement === 'valueFromSubjectInfo');
  const icon = detailItem.filter((state) => state.acMenuBlockElement === 'icon');
  const iconInverse = detailItem.filter((state) => state.acMenuBlockElement === 'iconInverse');

  return (
    <div
      className={`dashboard-box-info ${title[0].acMenuBlockElementValue == 'Van valute:' && `color-background-user-${user.companyAdditionalInfo && user.companyAdditionalInfo.acCompanyFinancialStatus.trim().toUpperCase()}`}`}
      style={{ borderBottom: `5px solid ${data.acMenuBlockColor}` }}
    >
      <div className="dashboard-box-info-icon">
        <div className={Object.keys(iconInverse).length !== 0 ? 'icon-dashboard-info-inverse icon-dashboard-info' : 'icon-dashboard-info'}
          style={
            Object.keys(icon).length !== 0 ?
              { fontSize: icon[0].acMenuBlockElementSize } :
              Object.keys(iconInverse).length !== 0 &&
              // { fontSize: iconInverse[0].acMenuBlockElementSize, backgroundColor: iconInverse[0].acMenuBlockElementColor }
              { fontSize: iconInverse[0].acMenuBlockElementSize }
          }>

          {Object.keys(icon).length !== 0 && <div style={{ color: icon[0].acMenuBlockElementColor }} dangerouslySetInnerHTML={{ __html: `${icon[0].acMenuBlockElementValue}` }} />}
          {Object.keys(iconInverse).length !== 0 && <div dangerouslySetInnerHTML={{ __html: `${iconInverse[0].acMenuBlockElementValue}` }} />}

        </div>
      </div>
      <div className="dashboard-box-info-text">
        <div className="dashboard-box-info-name" style={{ fontSize: title[0].acMenuBlockElementSize }}>{title[0].acMenuBlockElementValue}</div>
        {/* <div className={`dashboard-box-info-value dashboard-box-color-${color}`}>{ value }</div> */}
        <div className={`dashboard-box-info-value`} style={{ fontSize: value[0].acMenuBlockElementSize }}>{currencyFormat(parseFloat(value[0].acMenuBlockElementValue))}</div>
      </div>
    </div>
  );
};

export default DashboardBoxInfo;
