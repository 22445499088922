import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from '../components/sidebarMenu/Sidebar';
import Header from '../components/Header';
import CategorySidebar from '../components/CategorySidebar';

import ModalActionPopup from '../components/other/ModalActionPopup';
import CompareProducts from '../components/compare/CompareProducts';
import ScrollToTop from '../components/other/ScrollToTop';
import { useTranslation } from 'react-i18next';

const LefSidebar = () => {

    const getUserInfo = useSelector((state) => state.userInfo.userInfo);
    const messageInfo = useSelector((state) => state.messageInfo.items);
    const popups = useSelector((state) => state.getUserPopup.items);
    const { t } = useTranslation('routes');

    // testing dummy data
    // const [popups, setPopups] = useState(
    //     [{
    //         "anPopupKey": 55,
    //         "acPopupImage": "popup/2022/09/2022-09-21455589.jpg",
    //         "acBrend": null,
    //         "acPopupProduct": null,
    //         "acPopupContent": "",
    //         "acPopupTitle": "RAC21501",
    //         "acPopupLink": "https://www.ewe.rs/proizvod/amd-konfiguracije/ryzen-5-35008gb480gbgtx1650-4gb-notm-RAC21501",
    //         "anPopupOrgId": 1322,
    //         "acPopupType": "2",
    //         "acPopupSubtitle": "",
    //         "anPromoPrice": 0,
    //         "isPaymentAdvanceIncluded": 0,
    //         "isLoggedIn": 1,
    //         "anClicked": 40,
    //         "isSeen": 1,
    //         "isActive": 1,
    //         "acPopupLogo": "",
    //         "anPaymentAdvance": 0
    //     },
    //     {
    //         "anPopupKey": 56,
    //         "acPopupImage": "popup/2022/09/2022-09-21455589.jpg",
    //         "acBrend": null,
    //         "acPopupProduct": "RAC21501",
    //         "acPopupContent": "",
    //         "acPopupTitle": "RAC21501",
    //         "acPopupLink": "https://www.ewe.rs/proizvod/amd-konfiguracije/ryzen-5-35008gb480gbgtx1650-4gb-notm-RAC21501",
    //         "anPopupOrgId": 1322,
    //         "acPopupType": "0",
    //         "acPopupSubtitle": "",
    //         "anPromoPrice": 0,
    //         "isPaymentAdvanceIncluded": 0,
    //         "isLoggedIn": 1,
    //         "anClicked": 40,
    //         "isSeen": 0,
    //         "isActive": 1,
    //         "acPopupLogo": "",
    //         "anPaymentAdvance": 0
    //     }]
    // );

    const [popupsToShow, setPopupsToShow] = useState([]);
    const [isShown, setIsShown] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (getUserInfo) {
            let toShowPopup = popups.filter((res) => res.isSeen == 0);
            let toShowP = toShowPopup.filter((res) => res.isActive == 1);
            setPopupsToShow(toShowP);
            if (toShowP.length > 0) {
                setIsShown(true);
            } else {
                setIsShown(false)
            }
        }
    }, [popups]);

    // const isDesktop = window.innerWidth > 1020;

    return (
        <>
            {/* {!isDesktop ? (
                <Header />
            ) : (
                <> */}
            <Sidebar />
            {location.pathname != `/${t('prijava')}` && <CategorySidebar location={location} />}
            {/* </>
            )} */}

            {getUserInfo && isShown && <ModalActionPopup isShown={isShown} popups={popupsToShow} classModal={popupsToShow.length === 1 && popupsToShow.length !== 0 ? "modal-lg" : "modal-double-popoup"} />}
            <CompareProducts />
            <ScrollToTop />

        </>
    );
};

export default LefSidebar;