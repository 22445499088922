import { signout } from '../redux/actions/userActions';

// export function cookieCheckExist(dispatch) {
//     const user = Cookies.get('AuthToken');
//     if(!user) {
//         dispatch(signout());
//     };
// };

export function checkLoginExpire(dispatch) {
    // console.log(localStorage.getItem('endOfDay'), 'localStorage.getI');
    const expireDate = new Date(localStorage.getItem('endOfDay'));
    // const expireDate = new Date('Mon Jun 20 2022 13:26:00 GMT+0200 (Central European Summer Time)'); //test vreme
    const currentDate = new Date();

    if(localStorage.getItem('endOfDay')) {
        if(currentDate > expireDate) {
            dispatch(signout());
            return 0;
        } else {
            return 1;
        }
    } else {
        return 2;
    }
}