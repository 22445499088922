import { combineReducers } from 'redux';
import { USER_SIGNOUT } from '../contants/action-types'
import {
    userReducer,
    menuReducer,
    getUserNewsReducer,
    getUserPopupReducer,
    getUserBannerReducer,
    getUserSurveyReducer,
    getUserSettings,
    SetVatFunction,
    SetMessageInfo,
    SetSortFunction,
    SetViewFunction,
    SetCurrencyFunction,
    getUserVoucherList,
    getCompanyUserList,
    getCompanyDepartmentList,
    getRolesList,
    getSaleTargetList,
    getB2bSettings
} from './userReducers';

import {
    setSearchProducts,
    catalogProducts,
    getSearchItemsBox,
    compareReducer,
    compareReducerSpecifications,
    catalogDefaultFilters,
    finishedLoadingFilters,
    catalogFilters,
    catalogBannerProduct,
    getProductDetail,
    getProductSpecification,
    getWishList,
    catalogFiltersBrand,
    catalogFiltersSpecialAction,
    getProductRelatedSale,
    relatedCategoryProducts,
    getSearchBrands,
    getSearchCategories,
    getCustomPdfOffer,
    catalogFiltersPrice,
    weeklyProducts,
    inStockAgainProducts,
    recommendedProducts,
    setAuctionProducts,
    setActionProducts,
    setLastProductsList,
} from "./productReducers";

import {
    getLoyaltyProducts,
    getLoyaltySettings,
} from "./loyaltyReducers";

import {
    getMenuItems,
    getCatalogMenuItems,
    getBrandsMenuList,
    getCurrencyItems,
    getMenuSettings
} from './menuReducers';

import {
    cartReducer,
    getCartListFunction,
    getActiveCartFunction,
    getCartItemsList,
    getDeliveryTime,
    getCartComplete,
    getVoucherListsFunction,
    getDeliveryCity
} from './cartReducers';

import {
    getReclamationsItems,
    getBrendsItems,
    getDeliveryItems,
    getInvoiceItems,
    getBankAccountItems,
    getReservationItems,
    getSaleStatisticsItems,
    getTransactionItems,
    getNewsletterMaterialItems,
    getExportCatalogItems,
    getBenefitList,
    getBenefitOverview,
    getNewsKey,
    getCurrentServerTime,
    postBenefitPoints,
} from './otherReducers';

const reducers = combineReducers({
    userInfo: userReducer,
    productDetail: getProductDetail,
    menuInfo: menuReducer,
    searchProductsList: setSearchProducts,
    catalogProducts: catalogProducts,
    menuItems: getMenuItems,
    getCatalogMenuList: getCatalogMenuItems,
    getSearchItemsBox: getSearchItemsBox,
    compareItems: compareReducer,
    compareItemsSpecification: compareReducerSpecifications,
    cart: cartReducer,
    getUserNews: getUserNewsReducer,
    getUserPopup: getUserPopupReducer,
    getUserBanner: getUserBannerReducer,
    getUserSurvey: getUserSurveyReducer,
    getBrandsMenuList: getBrandsMenuList,
    catalogDefaultFilters: catalogDefaultFilters,
    finishedLoadingFilters: finishedLoadingFilters,
    catalogFilters: catalogFilters,
    catalogFiltersBrand: catalogFiltersBrand,
    catalogFiltersSpecialAction: catalogFiltersSpecialAction,
    catalogBannerProduct: catalogBannerProduct,
    getCartListFunction: getCartListFunction,
    getActiveCartFunction: getActiveCartFunction,
    getCartItemsList: getCartItemsList,
    getUserSettings: getUserSettings,
    getCurrencyItems: getCurrencyItems,
    activeCurrency: SetCurrencyFunction,
    activeVat: SetVatFunction,
    activeProductSort: SetSortFunction,
    activeProductView: SetViewFunction,
    getDeliveryCity: getDeliveryCity,
    getDeliveryTime: getDeliveryTime,
    getProductSpecification: getProductSpecification,
    messageInfo: SetMessageInfo,
    wishListItems: getWishList,
    getProductRelatedSale: getProductRelatedSale,
    getRelatedCategoryProducts: relatedCategoryProducts,
    getPdfCustom: getCustomPdfOffer,
    getSearchBrands: getSearchBrands,
    getSearchCategories: getSearchCategories,
    getLoyaltyProducts: getLoyaltyProducts,
    getLoyaltySettings: getLoyaltySettings,
    weeklyProducts: weeklyProducts,
    catalogFiltersPrice: catalogFiltersPrice,
    getReclamationsItems: getReclamationsItems,
    getBrendsItems: getBrendsItems,
    getDeliveryItems: getDeliveryItems,
    getCartComplete: getCartComplete,
    getInvoiceItems: getInvoiceItems,
    getBankAccountItems: getBankAccountItems,
    getVoucherList: getVoucherListsFunction,
    menuSettings: getMenuSettings,
    inStockAgainProducts: inStockAgainProducts,
    recommendedProducts: recommendedProducts,
    getAuctionProducts: setAuctionProducts,
    getActionProducts: setActionProducts,
    getLastProducts: setLastProductsList,
    getUserVoucherList: getUserVoucherList,
    getCompanyUserList: getCompanyUserList,
    getCompanyDepartmentList: getCompanyDepartmentList,
    getRolesList: getRolesList,
    getReservationItems: getReservationItems,
    getSaleStatisticsItems: getSaleStatisticsItems,
    getSaleTargetList: getSaleTargetList,
    getTransactionItems: getTransactionItems,
    getNewsletterMaterialItems: getNewsletterMaterialItems,
    getExportCatalogItems: getExportCatalogItems,
    getBenefitOverview: getBenefitOverview,
    getBenefitList: getBenefitList,
    getNewsKey: getNewsKey,
    getCurrentServerTime: getCurrentServerTime,
    postBenefitPoints: postBenefitPoints,
    getB2bSettings: getB2bSettings
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_SIGNOUT') {
        state = undefined;
    }
    return reducers(state, action);
}

export default rootReducer;