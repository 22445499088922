import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PDFViewer } from "@react-pdf/renderer";
import SecureLS from 'secure-ls';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';

import CompareProductsPdfItems from './templates/CompareProductsPdfItems';

const CompareProductsPdf = () => {
    const compareItems = useSelector((state) => state.compareItems.compareItems);
    const currency = useSelector((state) => state.activeCurrency.item.acShortName);
    const [filters, setFilters] = useState([]);
    const [productDetails, setProductDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const ls = new SecureLS({ encodingType: 'aes' });
    const getB2bSettings = useSelector((state) => state.getB2bSettings);
    const acLogOutUserCurrency = getB2bSettings.items ? getB2bSettings.items.acLogOutUserCurrency : '';

    const makeFilters = (com) => {
        let arrSpec = [];
        let spec = [];
        for (let i = 0; i < com.length; i++) {
            for (let y = 0; y < com[i].spec.list.length; y++) {
                arrSpec.push(com[i].spec.list[y]);
            }
        }
        let arra = arrSpec.sort((a, b) => parseFloat(a.anPriority) - parseFloat(b.anPriority));
        let filtersArr = [];
        let obj = {};
        arra.forEach((item, index) => {
            if (!obj[item.acGroupName]) {
                obj[item.acGroupName] = {};
                obj[item.acGroupName]["anPriority"] = item.anPriority;
                obj[item.acGroupName]["acGroupName"] = item.acGroupName;
                obj[item.acGroupName]["anGroupId"] = item.anGroupId;
                obj[item.acGroupName]["filters"] = [];
                item.filterSet.forEach((item2) => {
                    obj[item.acGroupName]["filters"].push(item2);
                });
            } else {
                item.filterSet.forEach((item2) => {
                    obj[item.acGroupName]["filters"].push(item2);
                });
                obj[item.acGroupName]["filters"].sort((a, b) => parseFloat(a.anPriority) - parseFloat(b.anPriority));
            }

            //filtrira da se ne duplira podgrupa
            obj[item.acGroupName]["filters"] = obj[item.acGroupName]["filters"].filter((v, i, self) => self.findIndex((itm) => itm.acFilterSet === v.acFilterSet) === i);
        });
        for (const property in obj) {
            filtersArr.push(obj[property]);
        }
        return filtersArr;
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            setLoading(true);

            const details = await Promise.all(compareItems.map(async (product) => {
                try {
                    const anId = product.anProductKey;
                    const userInfoData = ls.get('userInfo');
                    console.log(userInfoData, 'userInfoData');
                    const activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
                    const activeVat = JSON.parse(localStorage.getItem('activeVat'));

                    const attributes = {
                        anMainCategoryId: 0,
                        anCategoryId: 0,
                        anSubCategoryId: 0,
                        acBrand: '',
                        anId: anId !== "" && anId !== undefined && anId !== null ? anId : 0,
                        anCompanyKey: userInfoData ? userInfoData.company.anCompanyKey : 0,
                        acRebate: userInfoData ? userInfoData.company.acCompanyRebate : 'E',
                        isPdv: activeVat == 1 || activeVat == 0 ? activeVat : 1,
                        isEur: activeCurrency ? activeCurrency._id : 0,
                        anCatalogSortingType: 1,
                        anUserKey: userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0
                    };
                    const configRes = axiosinstance(attributes, "8224", "getProduct");

                    const response = await axios(configRes);
                    if (response.data.isOk == 1 && response.data.response && response.data.response.product) {
                        const items = response.data.response.product;

                        // Create an array of promises for each item.anProductKey
                        const promises = items.map(async item => {
                            const itemAttributes = { anProductKey: item.anProductKey };
                            const itemConfigRes = axiosinstance(itemAttributes, "8224", "productSpecification");
                            const itemResponse = await axios(itemConfigRes);
                            if (itemResponse.data.isOk == 1) {
                                // Extend the product with the specifications
                                item['spec'] = itemResponse.data.response;
                            }
                            return item;
                        });

                        // Use Promise.all to wait for all API calls to complete
                        const results = await Promise.all(promises);

                        console.log(results, 'results');
                        // Return the updated product
                        return results;
                    }
                } catch (error) {
                    console.log(error);
                }
                return null;
            }));

            const flattenedDetails = details.flat().filter(detail => detail !== null);

            console.log(flattenedDetails, 'details');
            setProductDetails(flattenedDetails);
            setLoading(false);
        };

        fetchProductDetails();
        console.log(compareItems, 'compareItems');
    }, [compareItems]);

    useEffect(() => {
        console.log(productDetails, 'productDetails');
        if (productDetails.length > 0) {
            setFilters(makeFilters(productDetails));
        }
    }, [productDetails]);

    return (
        <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            {productDetails.length > 0 && <CompareProductsPdfItems items={productDetails} currency={currency} filters={filters} acLogOutUserCurrency={acLogOutUserCurrency} />}
        </PDFViewer>
    );
}

export default CompareProductsPdf;
