import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getBrandsMenuList } from '../../redux/actions/menuActions';
import LoaderMask from '../other/LoaderMask';
import globalVariable from '../../global';
import { useTranslation } from 'react-i18next';

const Brand = () => {

    const dispatch = useDispatch();
    const getBrandsMenuListItems = useSelector((state) => state.getBrandsMenuList);
    const { t } = useTranslation('routes');
    const [isLoadingBrands, setIsLoadingBrands] = useState(true);

    useEffect(() => {
        dispatch(getBrandsMenuList()).then(() => {
            setIsLoadingBrands(false);
        });
    }, [dispatch]);

    if (!getBrandsMenuListItems) {
        return null;
    }

    return (
        <div>
            {isLoadingBrands ? (
                console.log('loading'), <LoaderMask />
            ) : (
                <ul className="menu-items">
                    {getBrandsMenuListItems.map((item, key) => (
                        <>
                            {item.anCnt > 0 && (
                                <Link key={key} className="brand-main-no-sub" to={`/${t('pretraga')}?q=${item.acBrand.toLowerCase()}&brand=${item.acBrand}`}>
                                    <li className="brand-menu-item" >
                                        <img src={`${globalVariable.imagesPath}manufacturers/${item.acBrand.replace(' 1****', '')}.png`} className="brand-menu-logo" /> {item.acBrand.replace(" 1****", "")} ({item.anCnt})
                                    </li>
                                </Link>
                            )}
                        </>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Brand;