import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import messages from '../../messages';
import { toast } from 'react-toastify';
import AddToCartButton from '../cart/AddToCartButton';
import { getProductSpecification } from '../../redux/actions/productActions';
import { Modal, Image, Container, Row, Col } from 'react-bootstrap';

import CurrencyFormat from '../other/CurrencyFormat';
import ModalLoyalty from '../other/ModalLoyalty';
import LoyaltyFinishModalSpecialAction from '../../components/modal/LoyaltyFinishModalSpecialAction';

import MessageInfoBox from '../../components/other/MessageInfoBox';
import { useTranslation } from 'react-i18next';


const LoyaltySpecialItem = ({ item, loyaltySettings, getUserInfo, shopIsActive, keyItem, mainItem, finishCart, setFinishCartFunction, setEmptyValues, emptyValues, isSpecialOpen, handleCancelButtonClick, itemKey, clickedSpecialItem, setShouldAnimate }) => {
    const isItem = item ? (item.anPrice ? true : false) : false;

    const dispatch = useDispatch();
    var percentProduct = 0;
    if (item && item.anPrice && item.anOldPrice) {
        percentProduct = 100 - (parseFloat(item.anPrice) * 100 / parseFloat(item.anOldPrice));
    }
    const [show, setShow] = useState(false);
    const [qtyValue, setQtyValue] = useState('');
    const { t } = useTranslation('loyalty');
    // console.log(item, "item")
    const [showPopup, setShowPopup] = useState(false);
    // console.log(isSpecialOpen, 'isSpecialOpen');
    // console.log(itemKey, 'index');

    const acBuyer = item && item.acBuyer && item.acBuyer ? item.acBuyer : "";

    // const [finishCart, setFinishCart = useState(false);
    // console.log(acBuyer, 'acBuyer');

    const now = Date.now();
    const adLoyaltyStartDate = Date.parse(loyaltySettings.adLoyaltyStartDate)
    const adLoyaltyEndDate = Date.parse(loyaltySettings.adLoyaltyEndDate)
    const loyaltyTypeId = (loyaltySettings.anLoyaltyTypeId);
    // const isLinkDisabled = (isSpecialOpen === 1 && acBuyer !== "") || isSpecialOpen === 2; //disejblovanje linka ako je onemogucena kupovina iz bilo kog razloga
    const isLinkDisabled = (isSpecialOpen === 1 || isSpecialOpen === 2) && acBuyer !== ""; //disejblovanje linka ako je onemogucena kupovina iz bilo kog razloga
    // console.log(loyaltyTypeId, "loyaltyTypeId");
    // const dummyNow = new Date(2022, 9, 26, 11, 23);
    // const parsedDummyDate = Date.parse(dummyNow)
    // console.log(loyaltySettings, 'loyaltySettings')

    const handleShow = () => { //modal za specifikaciju        
        setShow(true);
        dispatch(getProductSpecification(item.anProductKey));
    }

    const handleShowPopup = (event) => { //modal za kupovinu       
        // event.preventDefault();
        setShowPopup(true);
    }

    const handleCloseLoyaltyModal = () => setShow(false); //zatvaranje modala za specifikaciju    

    const handleClose = () => setShowPopup(false); //zatvaranje modala za kupovinu

    const renderElement = () => {

        if (shopIsActive == 0 && item.isPriceVisible == 1) {
            return (
                <>
                    <div className="special-product-price">
                        <span className="loyaltyOldPriceSpecial"><CurrencyFormat num={item.anOldPrice} /> {t('EUR')}</span>
                        <span className=""><CurrencyFormat num={item.anPrice} /> {t('EUR')}</span>

                    </div>
                    {/* <div className="percetange-loyatly-special-product">{parseInt(percentProduct)}<small>%</small></div> */}
                </>
            )
        } else if (shopIsActive == 1) {
            return (
                <>
                    <div className="special-product-price">
                        <span className="loyaltyOldPriceSpecial"><CurrencyFormat num={item.anOldPrice} /> {t('EUR')}</span>
                        <span className=""><CurrencyFormat num={item.anPrice} /> {t('EUR')}</span>

                    </div>
                    {/* <div className="percetange-loyatly-special-product">{parseInt(percentProduct)}<small>%</small></div> */}
                </>
            )
        } else {
            return (
                <>
                    <div className="t">{t('uskoro')}</div>
                    <div></div>
                </>
            )
        }
    };

    // console.log(item, 'item')

    const setQtyValueFunction = (name, val) => {

        if (val == "0" || (!isNumeric(val) && val != "")) {
            val = ""
            setQtyValue('');
            toast(<MessageInfoBox message="loyaltyNoZeroQty" type="0" />, {
                className: 'red-background',
            });
        } else {
            if (item.anQty < item.anQtyAvailableForCompany) {
                if (parseInt(val) > item.anQty) {
                    setQtyValue(item.anQty);
                    toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
                        className: 'red-background',
                    });
                } else {
                    setQtyValue(val);
                }
            } else {
                if (parseInt(val) > item.anQtyAvailableForCompany) {
                    setQtyValue(item.anQtyAvailableForCompany);
                    toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
                        className: 'red-background',
                    });
                } else {
                    setQtyValue(val);
                }
            }

            // if (parseInt(val) > item.anQty) {
            //     if (parseInt(val) > item.anQtyAvailableForCompany) {
            //         if (item.anQty <= item.anQtyAvailableForCompany) {

            //         }
            //         setQtyValue(item.anQtyAvailableForCompany);
            //         toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
            //             className: 'red-background',
            //         });
            //     } else {
            //         setQtyValue(item.anQty);
            //         toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
            //             className: 'red-background',
            //         });
            //     }
            // } else {
            //     setQtyValue(val);
            // }

            // if (parseInt(val) > item.anQtyAvailableForCompany) {
            //         setQtyValue(item.anQtyAvailableForCompany);
            //         toast(<MessageInfoBox message="loyaltyLimitQty" type="0" />, {
            //             className: 'red-background',
            //         });

            // } else {
            //     setQtyValue(val);
            // }
        }

        if (val == "") {
            let filteredArray = emptyValues.filter((item, index) => item.name !== name);
            setEmptyValues(filteredArray)
        } else {
            setEmptyValues([...emptyValues, { "name": name, "value": val }])
        }

    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the entirety of the string (`parseFloat` alone does not do this)...
            !isNaN(parseInt(str)) // ...and ensure strings of whitespace fail
    }
    //  const blockInvalidChar = e => ['e', 'E', '+', '-', '0'].includes(e.key) && e.preventDefault();

    useEffect(() => {
        setQtyValue('');
    }, [finishCart])
    // console.log(shopIsActive, "shopIsActive");
    const renderInputElement = () => {
        if (shopIsActive == 1 && getUserInfo.acCompanyLoyaltyStatus.trim().toUpperCase() != 'CRVEN' && loyaltySettings.anCompanyTarget < loyaltySettings.anCompanyTargetAchieved && (adLoyaltyStartDate <= now && adLoyaltyEndDate >= now)) {
            if (item.anQtyAvailableForCompany > 0) {
                return (
                    <input
                        type="text"
                        className="form-control loyalty-input-value"
                        data-product={item.anProductKey}
                        data-product-name={item.acProductName}
                        data-product-price={item.anPrice}
                        data-product-ident={item.acProduct}
                        data-product-id={item.anProductId}
                        value={qtyValue}
                        // onKeyDown={blockInvalidChar}
                        onChange={(e) => setQtyValueFunction(item.acProduct, e.target.value)}
                        disabled={item.anQty <= 0 || item.anQtyAvailableForCompany <= 0 && true}
                    />
                )
            } else {
                return null;
            }

        } else {
            return null;
        }


    }
    const renderInputElementTehnovacije = () => {
        if (shopIsActive == 1) {
            if (item.anQtyAvailableForCompany > 0) {
                return (
                    <input
                        type="text"
                        className="form-control loyalty-input-value"
                        data-product={item.anProductKey}
                        data-product-name={item.acProductName}
                        data-product-price={item.anPrice}
                        data-product-ident={item.acProduct}
                        data-product-id={item.anProductId}
                        value={qtyValue}
                        // onKeyDown={blockInvalidChar}
                        onChange={(e) => setQtyValueFunction(item.acProduct, e.target.value)}
                        disabled={item.anQty <= 0 || item.anQtyAvailableForCompany <= 0 && true}
                    />
                )
            } else {
                return null;
            }

        } else {
            return null;
        }
    }
    const renderButtonSubmitLoyalty = () => {
        // if (getUserInfo.acCompanyLoyaltyStatus && (getUserInfo.acCompanyLoyaltyStatus.trim().toUpperCase() != 'CRVEN' && loyaltySettings.anCompanyTarget < loyaltySettings.anCompanyTargetAchieved)) {

        return (
            <div className={`${emptyValues.length == 0 || emptyValues.filter(i => i.value == 0).length > 0 ? "button-submit-ly-shop-disabled" : ""} button-submit-ly-shop`}>
                <button type="submit" className="btn-sm btn w-100 btn-success add-special-product-btn" onClick={(e) => handleShowPopup()} ><i className="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp; {t('dodaj')}</button>
            </div>
        );
        // }
    };
    // console.log(isSpecialOpen, 'isSpecialOpen')

    return (
        <>
            {isItem &&
                // <div className={`${keyItem === 0 ? 'table-row-no-padding-top' : ''} loyalty-special-open-product-holder ${((isSpecialOpen === 1 || isSpecialOpen === 2) && acBuyer !== "") ? 'sold-out' : ''} ${clickedSpecialItem === keyItem ? 'flip' : ''}`} key={keyItem}>
                <div className={`${keyItem === 0 ? 'table-row-no-padding-top' : ''} loyalty-special-open-product-holder ${((isSpecialOpen === 1 || isSpecialOpen === 2) && acBuyer !== "") ? 'sold-out' : ''}`} key={keyItem}>
                    {(isSpecialOpen === 1 || isSpecialOpen === 2) && acBuyer !== "" && (
                        <div className="sold-text">Rasprodato</div>
                    )}


                    <div className="percetange-loyatly-special-product">{parseInt(percentProduct)}<small>%</small></div>
                    <div>
                        <div className="open-product-special-img">
                            <img src={`https://resource.ewe.rs/products/${item.acProduct}_v.jpg`} alt="" className="img-fluid img-loyalty-product" />
                        </div>
                    </div>
                    {/* <div>{item.acProduct}</div> */}
                    <div>
                        {item.isSpecialForCompany == 1 && (
                            <img src="/images/loyalty/special-for-company.png" className="special-icon-loyalty-for-company" alt="Loyalty Shop" />
                        )}
                        {item.isSaleConnected == 1 && (
                            <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja.png" />
                        )}
                        {item.acConditionType == 'PovezanaProdaja' && (
                            <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja-artikal.png" />
                        )}
                    </div>
                    <div className="special-name-prices-holder">
                        <div className={`loyalty-detail-name-click special-product-name-click ${isLinkDisabled ? 'disabled-link' : ''}`} onClick={isLinkDisabled ? null : (e) => handleShow()}>
                            {item.acProductName}
                        </div>
                        {/* <div className="text-align-right">{shopIsActive == 1 ? item.anQty : item.anQtyVirtual}</div> */}

                        {renderElement()}
                    </div>
                    {/* <div className="">
                    <span className="loyaltyOldPriceSpecial"><CurrencyFormat num={item.anOldPrice} /> <small>{t('EUR')}</small></span>s
                </div> */}

                    {/* <div>
                {loyaltyTypeId == 1 ? renderInputElement() : loyaltyTypeId == 2 ? renderInputElementTehnovacije() : null}
            </div> */}

                    <div className="special-product-button">
                        {
                            (isSpecialOpen === 1 || isSpecialOpen === 2) && acBuyer !== "" ? (
                                <div className="bought-2">
                                    <i class="fas fa-exclamation"></i>
                                    Kupio <span>{acBuyer}</span>
                                </div>
                            ) : isSpecialOpen === 1 && acBuyer === "" ? (
                                <>
                                    <div>
                                        {/* <button id={itemKey} className="cancelButton btn btn-sm btn-call w-100" onClick={() => handleCancelButtonClick(itemKey)}> */}
                                        <button id={itemKey} className="cancelButton btn btn-sm btn-call w-100" onClick={() => { handleCancelButtonClick(itemKey); }}>
                                            <i className="fas fa-ban" aria-hidden="true"></i>&nbsp; {t('odustani')}
                                        </button>
                                    </div>
                                    <div className="add-special-product addSpecialItemToCart text-center">
                                        {renderButtonSubmitLoyalty()}
                                    </div>
                                </>
                            ) : null
                        }
                    </div>


                    <ModalLoyalty show={show} item={item} handleClose={handleCloseLoyaltyModal} />

                    <Modal
                        show={showPopup}
                        onHide={handleClose}
                        keyboard={false}
                        dialogClassName='modal-xl'
                        aria-labelledby=""
                        centered
                    >
                        <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                            <i className="far fa-times-circle"></i>
                        </div>
                        <Modal.Header>
                            <h4>Potvrda kupovine</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <LoyaltyFinishModalSpecialAction acProduct={item.acProduct} anQty={item.anQty} anProductKey={item.anProductKey} anProductId={item.anProductId} acProductName={item.acProductName} anPrice={item.anPrice} setFinishCartFunction={finishCart} finishCart={finishCart} handleClose={handleClose} setEmptyValues={setEmptyValues} type={1} start={loyaltySettings.adLoyaltyStartDate} end={loyaltySettings.adLoyaltyEndDate} />

                            {/* <LoyaltyFinishModalSpecialAction setFinishCartFunction={setFinishCart} finishCart={finishCart} handleClose={handleClose} setEmptyValues={setEmptyValues} type={1} start={loyaltySettings.adLoyaltyStartDate} end={loyaltySettings.adLoyaltyEndDate} /> */}
                        </Modal.Body>
                    </Modal>
                </div >
            }
        </>
    );
};

export default LoyaltySpecialItem;