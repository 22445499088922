import React from "react";
import { useSelector } from "react-redux";
import { PDFViewer } from "@react-pdf/renderer";

import CustomCatalogPdfTemplate from "./templates/CustomCatalogPdfTemplate";
import SecureLS from 'secure-ls';

const CustomExportCatalogPDF = () => {

    let ls = new SecureLS({ encodingType: 'aes' });

    var productsList = ls.get('catalogItems');
    const currency = useSelector((state) => state.activeCurrency.item.acShortName);
    const activeVat = useSelector((state) => state.activeVat.item);

    return (
        <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            {productsList.length > 0 && <CustomCatalogPdfTemplate list={productsList} currency={currency} activeVat={activeVat} />}
        </PDFViewer>
    )
}

export default CustomExportCatalogPDF;
