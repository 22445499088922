import React from 'react';
import { SetCurrencyFunction } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { getSearchItemsPage, getWishList, getActionProductsList, getLastProductsList, getPopupProductDetail, fetchPostsProduct, getProductRelatedSale } from '../../redux/actions/productActions';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';


function useQuery() {
    return new URLSearchParams(useLocation().search);
};

const CuurencyMenuItem = ({ item, productItem, inputName }) => {

    const { t } = useTranslation('routes');
    let ls = new SecureLS({ encodingType: 'aes' });
    let query = useQuery();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const getUserBanner = useSelector((state) => state.getUserBanner.items);

    const catalogItems = location.pathname.includes('kreiraj') && ls.get('catalogItems') ? ls.get('catalogItems') : "";
    let acIdents = catalogItems != "" ? catalogItems.map(i => { return i.acProduct }) : "";
    const onChangeFunction = () => {
        dispatch(SetCurrencyFunction(item, location, query.toString(), history, acIdents));
        if (location.pathname.includes('pretraga') && !location.pathname.includes('uporedi-proizvode')) {
            dispatch(getSearchItemsPage(query.get("q"), query.get("brand"), query.get("category")));
        } else if (location.pathname.includes('lista-zelja')) {
            dispatch(getWishList());
        } else if (location.pathname.includes('specijalna-akcija')) {
            var filteredUrl = location.pathname.split('/');
            dispatch(getActionProductsList(filteredUrl[3], null, acIdents));
        } else if ((location.pathname.includes('pregledani-proizvodi') || location.pathname.includes('proizvod') || location.pathname.includes('производ')) && !location.pathname.includes('uporedi-proizvode')) {
            dispatch(getLastProductsList());
            if (location.pathname.includes('proizvod') || location.pathname.includes('производ')) {
                dispatch(getProductRelatedSale(params.productId));
            }
        } else if (location.pathname.includes(t('katalog'))) {
            let pathNameArr = location.pathname.split('/')
            let categoryId = pathNameArr[pathNameArr.length - 1]
            let userBanner = getUserBanner.filter(x => x.anCategoryKey == categoryId)
            if (userBanner[0]) {
                let productId = userBanner[0].acProducts.split(',');
                dispatch(fetchPostsProduct(0, 0, productId[0]));
            }
        } else if (location.pathname == '/') {
            dispatch(getActionProductsList(4, 'weekAction'));
            dispatch(getActionProductsList(2, 'inStockAgain'));
            dispatch(getActionProductsList(5, 'recommendedForYou'));
            if (productItem) {
                dispatch(getPopupProductDetail(productItem.anProductKey));
            }
        }
    };
    const gatherStatisticSwitchDashboard = () => {
        dispatch(globalStatistic(0, 'SWITCH_DASHBOARD'));
    }
    // console.log(activeCurrency, 'activeCurrency')
    return (
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                name={`priceEur${inputName}`}
                id={`priceEur${item._id}`}
                defaultValue={item._id}
                checked={activeCurrency._id === item._id}
                onClick={(e) => { onChangeFunction(e); gatherStatisticSwitchDashboard() }}
                onChange={e => { }} // warning fix
            />
            <label className="form-check-label" htmlFor={`priceEur${item._id}`}>{item.acShortName}</label>
        </div>
    );
};

export default CuurencyMenuItem;