import React, { useState, useEffect } from 'react';
import InputQtyPlusMinus from '../other/InputQtyPlusMinus';
import { Link } from 'react-router-dom';
import imageExists from 'image-exists';

// import styles from '../../css/Catalog.module.css'

import { useSelector, useDispatch } from 'react-redux';
import CurrencyFormat from '../other/CurrencyFormat';
import AddToCartButton from '../cart/AddToCartButton';

import WishListButton from '../other/WishListButton';
import CompareButton from '../other/CompareButton';
import CatalogProductBadge from './CatalogProductBadge';
import CatalogProductBadgeAction from './CatalogProductBadgeAction';
import CatalogProductBadgeImage from './CatalogProductBadgeImage';
import globalVariable from '../../global';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';


const CatalogBlockItem = ({ item, activeCart, set3DModalFunctionSet, cartModalFunction }) => {

    const imageExistsFunction = () => {
        var tmp = '';
        imageExists('https://resource3d.ewe.rs/' + item.acProduct.trim() + '/' + item.acProduct.trim() + '/images/up.png', img => {
            tmp = img;
        });
        return tmp;
    }

    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);
    const iconPath = process.env.PUBLIC_URL + '/media/';
    const getUserInfo = useSelector((state) => state.userInfo);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const getMenuSettings = useSelector((state) => state.menuSettings);
    const [qtyProduct, setQtyProduct] = useState(1);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    // var modalIcon = imageExistsFunction();
    const [modalIcon, setModalIcon] = useState(false);
    const userInfo = useSelector((state) => state.userInfo);
    const isLoggedIn = userInfo.userInfo;
    const getB2bSettings = useSelector((state) => state.getB2bSettings);

    const getUserSettings = useSelector((state) => state.getUserSettings);

    const { t } = useTranslation(['catalog', 'routes']);

    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    }

    const focusOnTop = () => {
        window.scrollTo(0, 0)
    };

    const addDefaultSrc = (e) => {
        e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
    };

    useEffect(() => {
        var menuSettingsItem = JSON.parse(localStorage.getItem('menuSettings'));
        setSidebarCollapsed(menuSettingsItem.otherPage)
    }, [getMenuSettings]);

    const set3DModalFunctionSetFunction = () => {
        set3DModalFunctionSet(item);
    }
    // console.log(item.specification, 'item')


    useEffect(() => {
        imageExists('https://resource3d.ewe.rs/' + item.acProduct.trim() + '/' + item.acProduct.trim() + '/images/up.png', img => {
            setModalIcon(img);
        });
    }, [item.acProduct]);

    return (
        <div className={`catalog-block-list-item ${sidebarCollapsed ? 'collapsed-sidebar-catalog' : ''}`}>
            <div className="catalog-block-list-item-header">
                <div className="catalog-item-block-header">
                    <div className="catalog-item-block-name">
                        <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`}>
                            {item.acSeoHeader ? item.acSeoHeader.replace(" 1****", "") : (item.acDept.replace(" 1****", "") + ' ' + item.acName)}
                        </Link>
                    </div>
                    <div className="catalog-item-block-buttons">
                        <div className="compare-button-catalog">
                            <CompareButton title={t('catalog:uporedi')} product={item} />
                        </div>
                        {getUserInfo.userInfo && (
                            <div className="wishlist-button-catalog">
                                <WishListButton title="lista zelja" product={item} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="catalog-item-block-body">
                    <div className="catalog-item-block-body-image">
                        <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`}>
                            <img src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}.jpg`} alt={item.acName} className="img-fluid catalog-item-block-image" onError={(e) => addDefaultSrc(e)} />
                        </Link>

                        <div className="prodcut-badges-holder">
                            {getUserInfo.userInfo && item.specialList != null && item.specialList.length > 0 && (
                                item.specialList.map((item, key) => (
                                    <CatalogProductBadgeAction view="tableItem" key={key} item={item} />
                                ))
                            )}

                            {/* izlistavanje specijalnih akcija vezana za sam artikal */}
                            {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
                                <>
                                    {item.acIconType == 0 && (
                                        <CatalogProductBadge view="tableItem" key={key} item={item} />
                                    )}
                                </>
                            ))}
                        </div>

                        <div className="prodcut-badges-images-holder">
                            {item.specialList && item.specialList.length > 0 && item.specialList.map((item, key) => (
                                item.acText == 'Vaucher' && item.acType == 'C' &&
                                <div className="product-catalog-badge-image" title={item.acNote}>
                                    <img src={`https://resource.ewe.rs/vouchers/vauceri.png`} alt="Vaučeri" className="img-fluid" />
                                </div>

                            ))}
                            {item.benefitPoints && isLoggedIn && item.benefitPoints.length > 0 && item.benefitPoints.map((item, key) => (
                                // item.acName == 'Voćkice' &&
                                <div className="product-catalog-badge-image" title={`U toku je prikpljanje poena za sencor akciju. Ovaj artikal vam donosi ${item.anPoints} poena`}>
                                    {item.anPoints == 5 &&
                                        <img src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_5.png`} alt="Bronzani novčić" className="img-fluid" />
                                    }
                                    {item.anPoints == 10 &&
                                        <img src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_10.png`} alt="Srebrni novčić" className="img-fluid" />
                                    }
                                    {item.anPoints == 20 &&
                                        <img src={`https://resource.ewe.rs/benefitpoint/sencor_aprililili_20.png`} alt="Zlatni novčić" className="img-fluid" />
                                    }
                                    {/* <span className='product-catalog-badge-coin-value'>{item.anPoints}</span> */}
                                </div>

                            ))}
                            {item.additionInfo.acAction && item.additionInfo.acAction.map((item, key) => (
                                <>
                                    {item.acIconType != 0 && (
                                        <CatalogProductBadgeImage key={key} item={item} />
                                    )}
                                </>
                            ))}
                            {modalIcon == 1 && (
                                <div
                                    className="product-catalog-badge-image product-catalog-badge-image-360"
                                    onClick={(e) => set3DModalFunctionSetFunction(item)}
                                >
                                    <img src={process.env.PUBLIC_URL + `/360logo.png`} alt="3D logo" className="img-fluid" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="catalog-item-block-body-content">
                        <table className="table-item-catalog-block">
                            <tbody>
                                <tr>
                                    <td>{t('catalog:sifra')}</td>
                                    <td className="catalog-block-item-value-table-td">{item.acProduct.trim()}</td>
                                </tr>
                                {item.specification != null && item.specification.map((item2, key2) => (
                                    <>
                                        {key2 < 5 && item2.filterSet.map((item3, key3) => (
                                            <>
                                                {key3 == 0 && (
                                                    <tr>
                                                        <td>{item3.acFilterSet}</td>
                                                        <td className="catalog-block-item-value-table-td" title={item3.filters}>{item3.filters}</td>
                                                    </tr>
                                                )}
                                            </>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="catalog-block-list-item-footer">
                <div className="catalog-item-block-footer">
                    <div className="catalog-item-block-price">
                        {getUserInfo.userInfo &&
                            <>
                                {item.anRecommendedRetailPrice > 0 && (
                                    <div className="catalog-item-block-price-value-mp" style={{ height: item.anRecommendedRetailPrice == 0 && "19px" }}>
                                    {t('catalog:MP')}: <CurrencyFormat num={item.anRecommendedRetailPrice} />  {item.anRecommendedRetailPrice != 0 && (getB2bSettings?.items?.acLogOutUserCurrency || t('catalog:RSD'))}
                                    </div>
                                )}
                            </>
                        }
                        {item.isPromotion == 1 && item.anOldPrice > 0 && getUserInfo.userInfo &&
                            <div className="catalog-item-block-price-value-old">
                                <CurrencyFormat num={item.anOldPrice} /> {item.anOldPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('catalog:RSD'))}
                            </div>
                        }
                        <div className={`catalog-item-block-price-value-price ${item.isPromotion == 1 && 'price-catalog-red'}`}><CurrencyFormat num={getUserInfo.userInfo ? item.anPrice : item.anRetailPrice} /> {getUserInfo.userInfo ? (item.anPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('catalog:RSD'))) : (item.anRetailPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('catalog:RSD')))}</div>
                    </div>
                    {getUserInfo.userInfo && (
                        <div className="catalog-item-block-stock">
                            <div className="catalog-item-block-stock-value">{t('catalog:lager')}: {item.anStock}</div>
                            <div className="catalog-item-block-reserved-value">{t('catalog:rezervisano')}: {item.anReserved}</div>
                        </div>
                    )}
                    <div className={`catalog-item-block-qty ${!getUserInfo.userInfo && 'catalog-item-block-qty-offline'}`}>

                        {getUserInfo.userInfo ? (
                            <>
                                {item.anStock > 0 ? (
                                    <>
                                        {item.anStock == item.anReserved ? (
                                            <Link to={`/${t('routes:kontakt')}`}>
                                                <button className="btn btn-sm btn-call w-100 catalog-block-item-contact"><img src={`${iconPath}icon-kontakt-telefon.svg`} alt="kontakt-telefon" />&nbsp;{t('catalog:pozovite')}</button>
                                            </Link>
                                        ) : (
                                            <>
                                                <InputQtyPlusMinus functionSetQty={functionQtyValue} qty={item.anStock} />
                                                {/* <div className="btn-add-to-cart-catalog"> */}
                                                <div className="text-center btn btn-success w-100 btn-sm">
                                                    <AddToCartButton qtyValue={qtyProduct} product={item} name={t('dodaj')} activeCart={activeCart} />
                                                </div>

                                            </>
                                        )}

                                    </>
                                ) : (
                                    <div>
                                        <Link to={`/${t('routes:kontakt')}`}>
                                            <button className="btn btn-sm btn-call w-100 catalog-block-item-contact"><img src={`${iconPath}icon-kontakt-telefon.svg`} alt="kontakt-telefon" />&nbsp;{t('catalog:pozovite')}</button>
                                        </Link>
                                    </div>
                                )}
                            </>
                        ) : (
                                <Link className="w-100" to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acSeoUrl)}`} onClick={(e) => focusOnTop()}>
                                <button className="btn btn-success w-100 btn-sm"><i className="fas fa-external-link-square-alt"></i>{t('catalog:detaljnije')}</button>
                            </Link>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CatalogBlockItem;