import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch, useSelector } from 'react-redux';
import { getLoyaltySettings } from '../redux/actions/productActions';
import styles from '../css/LoyaltyLanding.module.css';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation, Trans } from 'react-i18next';

const LoyaltyShopLandingPage = () => {
    const dispatch = useDispatch();
    const loyaltySettings = useSelector((state) => state.getLoyaltySettings);
    const getUserBanner = useSelector((state) => state.getUserBanner.items);
    const bennerListItems = getUserBanner.filter(x => x.anBannerPosition === 0);
    const bennerList = bennerListItems.filter(x => x.anPageKey === 363);
    const { t } = useTranslation('loyaltyShopLandingPage');

    const [overButton, setOverButton] = useState(0);
    const [pageSeoData, setPageSeoData] = useState({});

    useEffect(() => {
        dispatch(getPageSeo(363)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getLoyaltySettings());
        dispatch(globalStatistic(363, 'PAGE'));
    }, []);

    const focusOnTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className={`container-fluid`} >
                <div className={`row ${styles['loyalty-landing-homepage']}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/loyalty/pozadina.jpg'})` }}>
                    <div className="container">
                        <div className="row">
                            <div className={`${styles['top-holder-block']}`}>
                                <div className={`${styles['top-block-items']}`}>
                                    <div className={`${styles['top-block-item-logo']}`}>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/loyalty/logo-loyalty.png'} alt="" className="loyalty-image-img" />
                                    </div>
                                    <div className={`${styles['top-block-item-text']}`}>
                                        <h2><Trans i18nKey="loyaltyShopLandingPage:naslov1" components={{ span: <span></span>, br: <br /> }} /></h2>
                                        <p><Trans i18nKey="loyaltyShopLandingPage:podnaslov1" components={{ br: <br /> }} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={`${styles['banner-image-loyalty-landing']}`}>
                                {/* <img src={process.env.PUBLIC_URL + '/images/loyalty/kalup-za-proizvode-BANER.png'} alt="" className={`${styles['loyalty-image-img-banner']}`} /> */}
                                <img loading="lazy" src={`${bennerList.length > 0 && `https://resource.ewe.rs/media/${bennerList[0].acBannerImage}`}`} alt="Baner" className={`${styles['loyalty-image-img-banner']}`} />
                                <div className={`${styles['banner-image-text-bottom-loyalty-landing']}`}>
                                    <div className={`${styles['ly-landing-banner-title']}`}>
                                        <h2>{t('ne')} <span className={`${styles['ly-landing-banner-title-color']}`}>{t('moze')}</span><br /><span className={`${styles['ly-landing-banner-title-big']}`}>{t('jeftinije')}</span></h2>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/loyalty/loyalty-badge.png'} alt="Bedž" className={`${styles['loyalty-image-img-badge']}`} />
                                    </div>
                                    <div className={`${styles['ly-landing-banner-description']}`}>
                                        <Trans i18nKey="loyaltyShopLandingPage:naslov2" components={{ br: <br /> }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${styles['landing-second-block']}`}>
                    <div className="container">
                        <div className={`row`}>
                            <div className={`${styles['landing-second-block-holder']}`}>
                                <div className={`${styles['landing-second-block-items']}`}>
                                    <div className={`${styles['landing-second-block-left-side']}`}>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/loyalty/kvaka.png'} alt="Kvaka" className={`${styles['landing-second-block-image']}`} />
                                    </div>
                                    <div className={`${styles['landing-second-block-right-side']}`}>
                                        <h1><Trans i18nKey="loyaltyShopLandingPage:naslov3" components={{ span: <span></span>, br: <br /> }} /></h1>
                                        <p>{t('podnaslov2')}</p>
                                        <p>{t('podnaslov3')} <Link to={"/profil"} style={{ color: "#fff" }}>{t('linkProfil')}</Link>{t('podnaslov4')} <Link to={"/loyalty-artikli"} style={{ color: "#fff" }}>{t('linkArtikli')}</Link>.</p>
                                        <p>{t('paragraf1')} <Moment format="DD">{loyaltySettings.adLoyaltyStartDate}</Moment>., {t('paragraf2')}.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${styles['landing-third-block']}`}>
                    <div className="container">
                        <div className={`row`}>
                            <div className={`${styles['landing-third-block-holder']}`}>
                                <div className={`${styles['landing-third-block-items']}`}>
                                    <div className={`${styles['landing-third-block-left-side']}`}>
                                        <p1><Trans i18nKey="loyaltyShopLandingPage:paragraf3" components={{ br: <br /> }} /></p1>
                                        <h2><Trans i18nKey="loyaltyShopLandingPage:paragraf4" components={{ br: <br /> }} /></h2>
                                        <p>{t('paragraf5')}</p>
                                    </div>
                                    <div className={`${styles['landing-third-block-right-side']}`}>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/loyalty/racunar.png'} alt="Računar" className={`${styles['landing-third-block-image']}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${styles['landing-button-block']}`}>
                    <div className="col-sm-12">
                        <h3>{t('naslov4')}</h3>
                        <Link to="/loyalty-artikli">
                            <span
                                className={`${styles['landing-button-block-holder']}`}
                                onMouseOver={(e) => setOverButton(1)}
                                onMouseLeave={(e) => setOverButton(0)}
                                onClick={(e) => focusOnTop()}
                            >
                                {overButton == 1
                                    ? <img loading="lazy" src={process.env.PUBLIC_URL + '/images/loyalty/dugme2.png'} alt="" className={`${styles['landing-button-block-image']}`} />
                                    : <img loading="lazy" src={process.env.PUBLIC_URL + '/images/loyalty/dugme1.png'} alt="" className={`${styles['landing-button-block-image']}`} />
                                }
                            </span>
                        </Link>
                    </div>
                </div>
                <div className={`row ${styles['landing-footer-block-holder']}`}>
                    <div className={`${styles['landing-footer-block']}`}>
                        <p>{t('paragraf6')}</p>
                        <p>{t('paragraf7')}</p>
                        <p>{t('paragraf8')}</p>
                        <p>{t('paragraf9')}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoyaltyShopLandingPage
