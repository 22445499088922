import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import CartItem from '../components/cart/CartItem';
import CartInfoBoxItem from '../components/cart/CartInfoBoxItem';
import CartInfoDelivery from '../components/cart/CartInfoDelivery';
// import CartInfoAdress from '../components/cart/CartInfoAdress';
import CartInfoTotalSum from '../components/cart/CartInfoTotalSum';
// import CartBtnFinishOrder from '../components/cart/CartBtnFinishOrder';
import PageBanner from '../components/banner/PageBanner';
import { globalStatistic } from '../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';

import { removeCartItemFunction } from '../redux/actions/cartActions';
import CurrencyFormat from '../components/other/CurrencyFormat';

const CartPage = () => {

    const dispatch = useDispatch();
    const { cartId } = useParams();
    const iconPath = process.env.PUBLIC_URL + '/images/';


    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const cartActiveDetail3 = useSelector((state) => state.getActiveCartFunction[0]);

    const getCompanyAdditionalInfo = useSelector((state) => state.userInfo.userInfo.companyAdditionalInfo);
    const getCartListFunction = useSelector((state) => state.getCartListFunction.items);
    const [isBelgrade, setIsBelgrade] = useState(0);
    const [isVisibleInfoBoxDanger, setIsVisibleInfoBoxDanger] = useState(false);
    const getB2bSettings = useSelector((state) => state.getB2bSettings);
    const deliveryTypeSettings = getB2bSettings.items ? getB2bSettings.items.deliveryType : [];

    console.log(getB2bSettings, "getB2bSettings")

    var selectedCart = getCartListFunction.filter((x) => x._id === cartId);
    const { t } = useTranslation('cartPage');
    //    console.log(cartId,'korpa')
    if (selectedCart.length > 0) {
        var isActiveCurrent = selectedCart[0].isActive;

        if (isActiveCurrent === 1) {
            var cartActiveDetail = cartActiveDetail3;
            //setCartActiveDetail(cartActiveDetail3)
        } else {
            var cartActiveDetail = selectedCart[0];
        }
    } else {
        var cartActiveDetail = cartActiveDetail3;
    }

    const getUserBanner = useSelector((state) => state.getUserBanner.items);
    const bennerListItems = getUserBanner.filter(x => x.anPageKey == 369);

    const cartDeliveryCityFunction = (id) => {
        setIsBelgrade(id);
    }

    const removeCartItem = (anCartItemKey) => {
        dispatch(removeCartItemFunction(anCartItemKey, getActiveCartFunction));
    }

    var orderIsQualified = 1;
    if (activeCurrency.acShortName == 'RSD') {
        if (isBelgrade == 1 && cartActiveDetail.anValueNoPdv < getB2bSettings.anValueForFreeDelivery1) {
            orderIsQualified = 0;
        } else if (isBelgrade == 0 && cartActiveDetail.anValueNoPdv < getB2bSettings.anValueForFreeDelivery2) {
            orderIsQualified = 0;
        }
    } else {
        if (isBelgrade == 1 && cartActiveDetail.anValueNoPdv < getB2bSettings.anValueForFreeDelivery1 / getCompanyAdditionalInfo.anFXRatePostponed) {
            orderIsQualified = 0;
        } else if (isBelgrade == 0 && cartActiveDetail.anValueNoPdv < getB2bSettings.anValueForFreeDelivery2 / getCompanyAdditionalInfo.anFXRatePostponed) {
            orderIsQualified = 0;
        }
    }
    const gatherStatisticEmptyCart = () => {
        dispatch(globalStatistic(0, 'EMPTY_CART'));
    }

    let text = cartActiveDetail.acVoucherNote;
    if (text) {
        var pieces = text.split(/[\s,]+/)
        pieces[pieces.length - 1] = parseFloat(pieces[pieces.length - 1]).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        var parsedVoucherAmmount = '';
        pieces.forEach((item) => {
            parsedVoucherAmmount += item + ' ';
        })
    }

    return (
        <div className="container-content container-main-cart-content">
            <div className="container-fluid">
                <div className="row">

                    <div className="main-cart-item-content">
                        <div className="main-cart-items-table">
                            <div className="cart-editing-mobile-button">
                                <Link to="/uredjivanje-korpi">
                                    <span className="settings-cart-button">{t('urediteKorpe')} <i className="fas fa-sliders-h"></i></span>
                                </Link>
                            </div>
                            <div className="title-cart-order">{t('nazivKorpe')} (<span>{cartActiveDetail && cartActiveDetail.acCartName == 'Default' ? t('podrazumevaniNazivKorpe') : cartActiveDetail.acCartName}</span>)</div>
                            {cartActiveDetail && cartActiveDetail.cartItemsList && cartActiveDetail.cartItemsList.length > 0 ? (
                                <div className="main-cart-items-table-custom">
                                    <table className="table main-cart-table">
                                        <thead>
                                            <tr>
                                                <th>{t('kolona1')}</th>
                                                <th>{t('kolona2')}</th>
                                                <th>{t('kolona3')}</th>
                                                <th className="width-120-td">{t('kolona4')}</th>
                                                <th className="width-120-td">{t('kolona5')}</th>
                                                <th>{t('kolona6')}</th>
                                                <th className="text-center">{t('kolona7')}</th>
                                                <th className="width-120-td">{t('kolona8')}</th>
                                                <th className="width-150-td">{t('kolona9')}</th>
                                                <th className="width-120-td">{t('kolona10')}</th>
                                                {isActiveCurrent == 1 && (
                                                    <>
                                                        <th className="width-120-td">{t('kolona11')}</th>
                                                        <th></th>
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartActiveDetail.cartItemsList.map((item, key) => (
                                                <CartItem key={key} item={item} isActiveCurrent={isActiveCurrent} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                // <div className="alert alert-primary" role="alert">
                                //     Korpa je prazna
                                // </div>
                                <div className="empty-cart text-center">
                                    <img src={`${iconPath}korpa-siva.png`} alt="ikonica korpe" />
                                    <h5>{t('praznaKorpa')}</h5>
                                    <small>{t('nemaArtikalaUKorpi')}</small>
                                    <div className='empty-cart-button'>
                                        <Link to="/uredjivanje-korpi">
                                            <button className="btn btn-info">{t('povratak')}</button>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        {isActiveCurrent == 1 && (
                            <>
                                {cartActiveDetail && cartActiveDetail.cartItemsList &&
                                    cartActiveDetail.cartItemsList.length > 0 && (
                                        <div className="row item-detail-cart-forms">
                                            <div className="col-sm-12">
                                                <div className="btn-remove-all-cart">
                                                    {/* <span className="button-remove-all-item"><i className="far fa-trash-alt"></i> Isprazni korpu</span> */}
                                                    <span className="btn-warning btn-sm btn" onClick={(e) => { removeCartItem(0); gatherStatisticEmptyCart() }}><i className="far fa-trash-alt"></i> {t('isprazniKorpu')}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="main-cart-info-block">
                                                    {cartActiveDetail.anValueWithPdv != 0 && (
                                                        <>
                                                            {orderIsQualified == 1 && <CartInfoBoxItem isBelgrade={isBelgrade} totalSum={cartActiveDetail.anValueNoPdv} anFXRatePostponed={getCompanyAdditionalInfo.anFXRatePostponed} activeCurrency={activeCurrency} b2bSettings={getB2bSettings} text="" icon="fas fa-info" type="success" />}
                                                            {orderIsQualified == 0 && !isVisibleInfoBoxDanger && <CartInfoBoxItem isBelgrade={isBelgrade} totalSum={cartActiveDetail.anValueNoPdv} anFXRatePostponed={getCompanyAdditionalInfo.anFXRatePostponed} activeCurrency={activeCurrency} b2bSettings={getB2bSettings} text="" icon="fas fa-info" type="danger" />}
                                                            <CartInfoBoxItem isBelgrade={isBelgrade} totalSum={cartActiveDetail.anValueNoPdv} anFXRatePostponed={getCompanyAdditionalInfo.anFXRatePostponed} activeCurrency={activeCurrency} isVisibleInfoBoxDanger={isVisibleInfoBoxDanger} b2bSettings={getB2bSettings} text="" icon="fas fa-info" type="info" />
                                                        </>
                                                    )}
                                                    {cartActiveDetail && cartActiveDetail.acVoucherNote != '' &&
                                                        <div className="cart-info-box-voucher">
                                                            <img src='https://resource.ewe.rs/vouchers/vauceri.png' alt='robot'></img>
                                                            <span className='span-info-cart-box'>{parsedVoucherAmmount != undefined && parsedVoucherAmmount} {getB2bSettings?.items?.acLogOutUserCurrency || t('rsd')}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-4">
                                                <div className="promo-code-content">
                                                    <div className="promo-code-content-input">
                                                        <div className="input-group-sm mb-2">
                                                            <label>Promotivni kod:</label>
                                                            <div className="small-info-promo-code">
                                                                <small>Vaše mišljenje nam je važno i uvek se trudimo da ga uvažimo kako bismo unapredili naše usluge. Odgovorite na nekoliko pitanja o vašem iskustvu na našem sajtu.</small>
                                                            </div>
                                                        </div>
                                                        <div className="input-group input-group-sm mb-2">
                                                            <input type="text" className="form-control input-sm" />
                                                            <button className="btn btn-sm btn-success promo-code-button-check">Primeni promo kod</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-sm-6">
                                                <CartInfoTotalSum />
                                            </div>
                                            <div className="col-sm-12">
                                                <CartInfoDelivery cartDeliveryCity={cartDeliveryCityFunction} cartActiveDetail={cartActiveDetail} activeCurrency={activeCurrency} setIsVisibleInfoBoxDanger={setIsVisibleInfoBoxDanger} cartDeliveryType={deliveryTypeSettings} />
                                            </div>

                                        </div>
                                    )
                                }
                            </>
                        )}


                    </div>
                </div>
                <div className="row">
                    <div className="main-cart-banner">
                        <PageBanner categoryType="" categoryId="" pageId="369" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartPage;