import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { checkLoginExpire } from '../services/AuthService';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// const PublicRoute = ({component: Component, restricted, ...rest}) => {
const PublicRoute = ({ component: Component, sidebar: LeftSidebar, ...rest }) => {

    const dispatch = useDispatch();
    var checkExpireLogin = checkLoginExpire(dispatch);
    const { t } = useTranslation('routes');
    // console.log(checkExpireLogin, 'checkExpireLogin')
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route
            {...rest}
            render={ props =>
                checkExpireLogin == 0 ? (
                    <Redirect to={{ pathname: `/${t('prijava')}` }} />
                ) : (
                    <>
                        <LeftSidebar {...props} />
                        <Component {...props} />
                    </>
                )
            }
        />
    );
};

export default PublicRoute;