import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormInput from '../components/form/FormInput';
import MessageInfoBox from '../components/other/MessageInfoBox';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';

import globalVariable from '../global';
import captchaImg from '../../src/img/captcha.jpg';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation, Trans } from 'react-i18next';

const ContactPage = () => {

    const dispatch = useDispatch()
    const queries = useMediaQuery('(max-width: 1279px)');
    const userInfo = useSelector((state) => state.userInfo);
    const userEmails = userInfo.userInfo ? userInfo.userInfo.company.acCompanyMail1.split(';') : '';
    const [captcha, setCaptcha] = useState('I');
    const [honeyPot, setHoneyPot] = useState(0);
    const [captchaInput, setCaptchaInput] = useState({
        captchaValue: ""
    });
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('contactPage');

    const characters = 'qwertyuopasdfghjkzxcvbnm1234567890';
    // var captcha = '';
    useEffect(() => {
        dispatch(getPageSeo(378)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        generateString(6) // Function called here and save in captcha variable
        dispatch(globalStatistic(378, 'PAGE'));
    }, [])

    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
        //  return result;
    }



    const [values, setValues] = useState({
        emailContact: userEmails[0],
        nameContact: "",
        textContact: "",
        captchaValue: ""
    });

    const [errors, setErrors] = useState({
        emailContact: 1,
        nameContact: 1,
        textContact: 1,
        captchaValue: 1
    });

    const [validInput, setValidInput] = useState({
        emailContact: 0,
        nameContact: 0,
        textContact: 0,
        captchaValue: 0
    })

    const onChangeFunction = (e) => {
        if (e.target.name == 'contact_us_via_pigeon') {
            if (e.target.value != '') {
                setHoneyPot(1)
            } else {
                setHoneyPot(0)
            }
        }
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(captcha)
        // console.log(captchaInput.captchaValue, 'captchaInput.captchaValue')
        if (captcha == captchaInput.captchaValue) {
            // honeypot provera
            if (honeyPot == 0) {
                setValidInput({
                    ...validInput,
                    emailContact: values.emailContact == '' && 1,
                    nameContact: values.nameContact == '' && 1,
                    textContact: values.textContact == '' && 1,
                    captchaValue: values.captchaValue == '' && 1,
                });



                if (errors.emailContact == 0 && errors.nameContact == 0 && errors.textContact == 0 && errors.captchaValue == 0) {

                    //slanje email-a
                    axios({
                        method: "POST",
                        url: "https://newb2b.ewe.rs:5004/contact",
                        data: {
                            values: values,
                        }
                    }).then((response) => {

                        if (response.data.isOk == 1) {

                            toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                                className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                            });

                            //resetovanje inputa i provera obaveznih polja
                            setValidInput({
                                emailContact: 0,
                                nameContact: 0,
                                textContact: 0,
                                captchaValue: 0
                            });

                            setErrors({
                                emailContact: 1,
                                nameContact: 1,
                                textContact: 1,
                                captchaValue: 1,
                            })

                            setValues({
                                emailContact: "",
                                nameContact: "",
                                textContact: "",
                                captchaValue: "",
                            });

                            setCaptchaInput({
                                captchaValue: ""
                            })

                        }
                    });

                } else {
                    toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                        className: 'red-background',
                    });
                }
            } else {
                toast(<MessageInfoBox message='somethingWentWrong' type={0} />, {
                    className: 'red-background',
                });
            }
        } else {
            setCaptchaInput({
                captchaValue: ""
            })

            toast(<MessageInfoBox message='errorRequiredCaptcha' type={0} />, {
                className: 'red-background',
            });
        }
    };


    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setValues({ ...values, [name]: value });
        captchaInput[name] = value;
        setCaptchaInput(captchaInput);
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="website"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content contact-page-content">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="378" />
                            </div>
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                            {/* <div className="contact-title">
                                <h1>Kontakt forma</h1>
                            </div> */}
                            {/* <div className="contact-desc">Poštovani partneri, u saradnji sa servisom Nepo System doo obezbedili smo besplatnu PickUp&Return uslugu za sve uređaje kupljne kod nas. Molimo Vas popunite formu ispod kako biste zakazali preuzimanje uređaja od strane kurirske službe. Kako bi smo najbrže rešili Vaš reklamaciju molimo Vas da nam pomognete unošenjem detalja uređaja koji šaljete.</div> */}


                            <div className="contact-form22">
                                {/* <div className="contact-form-title">Kontaktirajte nas</div> */}
                                <div className="contact-form-body">
                                    <div className="form-body-holder-contact">
                                        <div className="form-contact-holder">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h4 className="contact-form-col-title">{t('naslov2')}</h4>
                                                    <p><Trans i18nKey="contactPage:kontaktPodaci" components={{ br: <br /> }} /></p>
                                                    <p><Trans i18nKey="contactPage:ostaleInformacije" components={{ br: <br /> }} /></p>
                                                    <p><Trans i18nKey="contactPage:brojeviRacuna" components={{ br: <br /> }} /></p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <form name="contactFormLogout" className="contactFormLogout">
                                                        <div className="form-group">
                                                            <FormInput
                                                                type="text"
                                                                name="nameContact"
                                                                placeholder={t('placeholder1')}
                                                                isRequired="1"
                                                                nameclassName="contactName"
                                                                messageError={t('porukaGreske')}
                                                                label={t('inputLabel1')}
                                                                value={values['nameContact']}
                                                                onChange={(e) => onChangeFunction(e)}
                                                                validInputValue={validInput.nameContact}
                                                                setErrorsFunc={setErrorsFunction}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <FormInput
                                                                type="email"
                                                                name="emailContact"
                                                                placeholder={t('placeholder2')}
                                                                isRequired="1"
                                                                nameclassName="contactEmail"
                                                                messageError={t('porukaGreske2')}
                                                                label={t('inputLabel2')}
                                                                value={values['emailContact']}
                                                                onChange={(e) => onChangeFunction(e)}
                                                                validInputValue={validInput.emailContact}
                                                                setErrorsFunc={setErrorsFunction}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <FormInput
                                                                type="textarea"
                                                                name="textContact"
                                                                isRequired="1"
                                                                nameclassName="contactDesc"
                                                                placeholder={t('placeholder3')}
                                                                messageError={t('porukaGreske')}
                                                                label={t('inputLabel3')}
                                                                value={values['textContact']}
                                                                onChange={(e) => onChangeFunction(e)}
                                                                validInputValue={validInput.textContact}
                                                                setErrorsFunc={setErrorsFunction}
                                                            />
                                                        </div>
                                                        <div className="form-group row contact-captcha-image-code unselectable">
                                                            <div className="mask-captcha-loyalty"></div>
                                                            <h4 id="captcha" style={{ top: "25px", left: "50%", position: "absolute", transform: "translate(-50%, 0)" }}>{captcha}</h4>
                                                            <img src={captchaImg} alt='Captcha' className="mt-3 mb-3 w-100" height="50" />
                                                        </div>
                                                        <FormInput
                                                            type="text"
                                                            name="captchaValue"
                                                            placeholder={t('placeholder4')}
                                                            nameclassName="captchaVal"
                                                            messageError={t('porukaGreske3')}
                                                            isRequired="1"
                                                            value={values['captchaValue']}
                                                            onChange={(e) => handleChange(e)}
                                                            validInputValue={validInput.username}
                                                            setErrorsFunc={setErrorsFunction}
                                                            autoComplete="off"
                                                        />
                                                        {/* HONEY POT POLJE */}
                                                        <input type="text" name="contact_us_via_pigeon" value="" style={{ display: "none" }} autoComplete="off" onChange={(e) => onChangeFunction(e)} />
                                                        <div className="button-add-holder-article">
                                                            <button type="submit" className="btn btn-info btn-add-article-pickupreturn" onClick={handleSubmit}>{t('posalji')}</button>
                                                        </div>

                                                    </form>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="contact-map">
                                                        <iframe title='googleMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.300748519168!2d20.382860016195117!3d44.856171881827066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a65d297d2cb85%3A0x4e1b148a77683fa6!2z0KbQsNGA0LAg0JTRg9GI0LDQvdCwIDIxMiwg0JHQtdC-0LPRgNCw0LQ!5e0!3m2!1ssr!2srs!4v1655208267804!5m2!1ssr!2srs" style={{ border: "0" }} allowFullScreen={""} loading={"lazy"} referrerpolicy={"no-referrer-when-downgrade"}></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica nabavke' src={`${globalVariable.domainName}/media/contactPage/nabavka.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov3')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail1')}`} target="_self">{t('mail1')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica prodaje' src={`${globalVariable.domainName}/media/contactPage/prodaja.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov4')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail2')}`} target="_self">{t('mail2')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica briga o klijentima' src={`${globalVariable.domainName}/media/contactPage/briga-o-klijentima.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov5')}</h4>
                                                                {t('telefon2')}<br />
                                                                <a href={`mailto:${t('mail3')}`} target="_self">{t('mail3')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica poslovnih resenja' src={`${globalVariable.domainName}/media/contactPage/poslovna-resenja.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov6')}</h4>
                                                                {t('telefon3')}<br />
                                                                <a href={`mailto:${t('mail4')}`} target="_self">{t('mail4')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica marketinga' src={`${globalVariable.domainName}/media/contactPage/marketing.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov7')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail5')}`} target="_self">{t('mail5')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica reklamacija' src={`${globalVariable.domainName}/media/contactPage/reklamacije.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov8')}</h4>
                                                                {t('telefon4')}<br />
                                                                <a href={`mailto:${t('mail6')}`} target="_self">{t('mail6')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica rasporeda transporta' src={`${globalVariable.domainName}/media/contactPage/raspored-transporta.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov9')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail7')}`} target="_self">{t('mail7')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica servisa' src={`${globalVariable.domainName}/media/contactPage/servis.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4><Trans i18nKey="contactPage:naslov10" components={{ br: <br /> }} /></h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail8')}`} target="_self">{t('mail8')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica racunovodstva' src={`${globalVariable.domainName}/media/contactPage/racunovodstvo.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov11')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail9')}`} target="_self">{t('mail9')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ContactPage;