import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getSearchItemsPage } from '../redux/actions/productActions';
import { getBrandsMenuList } from '../redux/actions/menuActions';

import CatalogPreviewBlock from '../components/catalog/CatalogPreviewBlock';
import PageHelmet from '../components/helmet/PageHelmet';
import CatalogItem from '../components/catalog/CatalogItem';
import { useTranslation } from 'react-i18next';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SearchPage = () => {

    let query = useQuery();
    const dispatch = useDispatch();
    const productsList = useSelector((state) => state.searchProductsList);
    const getSearchCategories = useSelector((state) => state.getSearchCategories);
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);
    const getBrandsMenuListItems = useSelector((state) => state.getBrandsMenuList);
    const [currentBrand, setCurrentBrand] = useState({});
    const { t } = useTranslation('searchPage');
    const fetchProducts = async () => {
        dispatch(getSearchItemsPage(query.get("q"), query.get("brand"), query.get("category")));
    };

    useEffect(() => {
        if (query.get("q") && query.get("q") !== "") {
            // dispatch(removeSetSearchProducts());
            fetchProducts();
        }
        if (query.get("brand")) {
            dispatch(getBrandsMenuList(query.get("brand").trim()));
        }
        // console.log(getBrandsMenuListItems, 'getBrandsMenuListItems from SearchPage');

    }, [query.get("q"), query.get("brand"), query.get("category")]);

    // useEffect(() => {
    //     // console.log(getBrandsMenuListItems[0], 'filter brand')
    //     // setCurrentBrand(extractObjectByBrand(getBrandsMenuListItems, query.get("q").replace(" 1****", "")));
    //     setCurrentBrand(getBrandsMenuListItems[0]);
    // }, [getBrandsMenuListItems]);

    useEffect(() => {
        //preuzima brend iz url-a
        const brandFromUrl = query.get("brand");
        //pronalazi brend u getBrandsMenuListItems nizu koji odgovara brendu iz URL-a
        const brandObject = getBrandsMenuListItems.find(item => item.acBrand === brandFromUrl);
        //postavlja currentBrand na pronadjeni objekat
        setCurrentBrand(brandObject);
    }, [getBrandsMenuListItems]);

    var staticUrl = `?q=${query.get("q")}`;
    var categoryUrl = '';
    var brandUrl = '';
    if (query.get("brand")) {
        categoryUrl = staticUrl + '&brand=' + query.get("brand");
    } else {
        categoryUrl = staticUrl;
    }
    if (query.get("category")) {
        brandUrl = staticUrl + '&category=' + query.get("category");
    } else {
        brandUrl = staticUrl;
    }

    // if(productsList) {
    //     return null;
    // }

    var acSubCategory = '';

    if (getSearchCategories.length > 0) {
        getSearchCategories.forEach(element => {
            if (element.anSubCategoryKey == query.get("category")) {
                acSubCategory = element.acSubCategory;
            }
        });
    }

    // stara funkcija koja izvlaci brend iz seta svih brendova
    // const extractObjectByBrand = (array, brand) => {
    //     const lowerCaseBrand = brand.toLowerCase().trim();
    //     return array.find(obj => obj.acBrand.toLowerCase() === lowerCaseBrand);
    // };

    return (
        <div className="container-content container-catalog-content search-content-box">
            <div className="container-fluid">
                {!query.get("brand") ?
                    <div className="row">
                        <div className="search-block-content">
                            <div className="info-alert-search">
                                <span>{t('naslov')}</span> <b>{query.get("q").replace(" 1****", "")}</b> {productsList.loading == false && (
                                    <>
                                        ({productsList.items.length})
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    : (
                        currentBrand && (
                            <>
                                <PageHelmet
                                    title={`${currentBrand.acSeoTitle}`}
                                    description={`${currentBrand.acSeoDescription}`}
                                    keywords={`${currentBrand.acSeoKeywords}`}
                                    ogDescription={`${currentBrand.acSeoDescription}`}
                                    ogTitle={`${currentBrand.acSeoTitle}`}
                                    ogUrl={`https://ewe.rs${window.location.pathname}`}
                                    ogImg={`https://resource.ewe.rs/manufacturers/${currentBrand.acBrand}.png`}
                                    twitterDescription={`${currentBrand.acSeoDescription}`}
                                />
                                <div className="row">
                                    <div className="main-title-categroy-item">
                                        {console.log(currentBrand.acSeoHeader)}
                                        {<h1>{currentBrand.acSeoHeader}</h1>}
                                        {<h4>{currentBrand.AcSeoShortPageDescription}</h4>}
                                    </div>
                                </div>
                            </>
                        )
                    )
                }
                <div className="row">

                    {(function () {
                        if (query.get("category")) {
                            return (
                                <div className="catalog-selected-filters-content">
                                    <div className="catalog-selected-filters-title">{t('odabraniFilteri')}</div>
                                    <div className="catalog-selected-filters-items">
                                        {query.get("brand") && (
                                            <div className="catalog-filter-top-item active">{t('brend')} {query.get("brand").replace(" 1****", "")}</div>
                                        )}
                                        {query.get("category") && (
                                            <Link className="link-filtered-search" to={categoryUrl}>
                                                <div className="catalog-filter-top-item active">{t('kategorija')} {acSubCategory} <i className="fas fa-times"></i></div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    })()}



                </div>
                <div className="row">
                    <CatalogPreviewBlock itemsList={productsList} sortable="1" preview="1" export="1" itemPreview="catalog" pdfType="pretraga" />
                </div>
            </div>
        </div>
    );
};

export default SearchPage;