import React from "react";
import { useSelector } from 'react-redux';

import BlockActionArticleItem from './BlockActionArticleItem';

const BlockActionArticle = (props) => {

    const { item, index, checkSeenPopup, show } = props;

    var itemsToShow = []
    var popups = []
    if (item.length > 0) {
        popups = item;
    } else {
        popups = [item]
    }

    if (item.length > 0) {
        itemsToShow = item.filter(i => i.acPopupType != "2")
    }
    // console.log(item, 'item')
    // console.log(itemsToShow, 'itemtoshow')
    // console.log(index, 'index')
    // console.log(popups, 'popups ')
    return (
        <>
            {popups.length > 0 ? (

                itemsToShow && itemsToShow.length > 0 ? (
                    <BlockActionArticleItem checkSeenPopup={checkSeenPopup} show={show} item={itemsToShow[0]} />
                ) : (
                    <BlockActionArticleItem checkSeenPopup={checkSeenPopup} show={show} item={popups[0]} />
                )
            ) : (
                index == undefined &&
                <div className="action-block action-block-no-content">
                    <div className="action-block-content action-block-content-no-content">
                        <img src="https://resource.ewe.rs/media/banners/2022/08/2022-08-04868740.gif" alt="Placeholder" title="Placeholder GIF video" ></img>
                    </div>
                </div>
            )
            }
        </>

    );
};

export default BlockActionArticle;